import { joiResolver } from "@hookform/resolvers/joi";
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Col,
  Container,
  FileInput,
  Flex,
  Grid,
  Group,
  Image,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Switch,
  Table,
  Text,
  TextInput,
  Textarea,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconArrowRight,
  IconCheck,
  IconFileUpload,
  IconPhoto,
  IconPlus,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons";
import produce from "immer";
import Joi from "joi";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import dummyData from "./dummyData.json";
import { getAlteredCategories, getAlteredCities } from "./helperFunctions";
import { products_api } from "./products.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { image_url } from "../../../config";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";
import { categories_api } from "../categories/category.service";

const useStyles = createStyles(theme => ({
  disabled: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    borderColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2],
    cursor: "not-allowed",

    "& *": {
      color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
    },
  },
}));

function AddProductForm() {
  // Configs
  const { classes } = useStyles();
  const isMobileScreen = useMediaQuery("(max-width: 768px)");
  const max_images = 5;
  const theme = useMantineTheme();
  const navigate = useNavigate();

  // Fetching categories & setting them
  const categories = useQuery("product_categories", categories_api.get, {
    onSuccess: data => {
      const actual_data = getAlteredCategories(data.data);
      console.log(actual_data);
      setProductCategories(actual_data);
    },
  });

  // Fetching cities
  const get_city = useQuery("cities", products_api.getCities, {
    onSuccess: data => {
      const actual_data = getAlteredCities(data.data);
      console.log(actual_data);
      setCityGroups(actual_data);
    },
  });

  const [isEditing, setIsEditing] = useState(window.location.pathname.includes("/edit"));
  const [existingProductImages, setExistingProductImages] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [isEditing, setIsEditing] = useState(false);
  const [files, setFiles] = useState([]);
  const [dropzoneErrorMessage, setDropzoneErrorMessage] = useState(null);

  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);

  const [cityGroups, setCityGroups] = useState([]);
  const [cities, setCities] = useState([]);

  const [specifications, setSpecifications] = useState([]);

  const [searchParams] = useSearchParams();

  const handleCategoryChange = value => {
    console.log(getValues("category_id"));

    let sub_cats = productCategories.find(e => e.id === value);

    if (productSubCategories.length) {
      console.log("c1");
      console.log({
        v1: getValues("category_id"),
        v2: value,
      });
      if (getValues("category_id") !== value) {
        setValue("category_id", value);
        console.log("entered");
        setValue("sub_category_id", "");
        setProductSubCategories(sub_cats.sub_categories);
      }
    } else {
      setValue("category_id", value);
      setProductSubCategories(sub_cats.sub_categories);
    }
  };

  const handleCityChange = value => {
    console.log(getValues("city_group"));

    let sub_cats = cityGroups.find(e => e.id === value);

    if (cities.length) {
      if (getValues("city_group") !== value) {
        setValue("city_group", value);
        setValue("city_id", "");
        setCities(sub_cats.cities);
      }
    } else {
      setValue("city_group", value);
      setCities(sub_cats.cities);
    }
  };

  const emptyMessage = label => {
    return {
      "string.empty": `${label} is required.`,
      "number.base": `${label} is required.`,
    };
  };

  const validationSchema = Joi.object({
    title: Joi.string().required().messages(emptyMessage("Product Title")),
    product_code: Joi.string().required().messages(emptyMessage("Product Code")),
    hsn_code: Joi.string().required().messages(emptyMessage("HSN / SAC Code")),
    category_id: Joi.number().required().messages(emptyMessage("Category")),
    sub_category_id: Joi.number().required().messages(emptyMessage("Sub Cateogry")),
    thumbnail: isEditing
      ? Joi.optional().allow(null, "")
      : Joi.object().required().messages(emptyMessage("Product Thumbnail")),
    short_description: Joi.string().required().messages(emptyMessage("Short Description")),
    long_description: Joi.string().required().messages(emptyMessage("Long Description")),
    length: Joi.optional().allow(null).messages(emptyMessage("Length")),
    breadth: Joi.optional().allow(null).messages(emptyMessage("Breadth")),
    height: Joi.optional().allow(null).messages(emptyMessage("Height")),
    weight: Joi.optional().allow(null).messages(emptyMessage("Weight")),
    delivery_charges: Joi.optional().allow(null, ""),
    cod: Joi.optional().allow(null, ""),
    verified: Joi.optional().allow(null, ""),
    assured: Joi.optional().allow(null, ""),
    deals: Joi.optional().allow(null, ""),
    featured: Joi.optional().allow(null, ""),
    active: Joi.optional().allow(null, ""),
    in_stock: Joi.optional().allow(null, ""),
    mrp: Joi.number().required().messages(emptyMessage("MRP")),
    selling_price: Joi.number().max(Joi.ref("mrp")).required().label("Selling Price").messages({
      "number.base": "Selling Price is required.",
      "number.max": "Selling Price must be less than MRP.",
    }),
    tax: Joi.number().required().max(100).messages(emptyMessage("Tax")),

    //
    city_group: Joi.number().required().messages(emptyMessage("City Group")),
    city_id: Joi.number().required().messages(emptyMessage("City")),
  });

  const {
    control,
    register: form,
    handleSubmit,
    reset,
    setValue,
    setValues,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      product_code: "",
      hsn_code: "",
      title: "",
      category_id: null,
      sub_category_id: null,
      short_description: "",
      long_description: "",
      thumbnail: null,
      length: "",
      breadth: "",
      height: "",
      weight: "",
      delivery_charges: "",
      cod: "",
      verified: null,
      deals: null,
      assured: null,
      featured: null,
      //
      active: true,
      in_stock: true,
      tax: "",
      city_group: "",
      city_id: "",
      //
      mrp: "",
      selling_price: "",
    },
    resolver: joiResolver(validationSchema),
  });

  console.log('qqq-2223',control)

  const deleteImage = index => {
    setFiles(
      produce(draft => {
        draft.splice(index, 1);
      })
    );
  };

  const handleSpecifications = {
    add: () => {
      setSpecifications(specifications => [
        ...specifications,
        {
          title: "",
          description: "",
        },
      ]);
    },
    delete: index => {
      let temp = [...specifications];
      temp.splice(index, 1);
      setSpecifications(temp);
    },
    update: (index, e) => {
      let newArray = [...specifications];
      let el = newArray[index];
      el[e.target.name] = e.target.value;
      setSpecifications(newArray);
    },
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Box pos={"relative"} bg={"transparent"} key={index}>
        <ActionIcon
          variant={"filled"}
          color={"red"}
          size="md"
          pos={"absolute"}
          right={0}
          top={0}
          p={5}
          sx={{ zIndex: 5, borderRadius: 20 }}
          onClick={() => {
            deleteImage(index);
          }}
        >
          <IconX style={{ color: "white" }} />
        </ActionIcon>
        <Image
          key={index}
          height={120}
          width={120}
          fit="contain"
          src={imageUrl}
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Box>
    );
  });

  const exitingImages = existingProductImages.map((image, index) => {
    // const imageUrl = URL.createObjectURL(file);
    return (
      <Box pos={"relative"} bg={"transparent"} key={index}>
        <ActionIcon
          variant={"filled"}
          color={"red"}
          size="md"
          pos={"absolute"}
          right={0}
          top={0}
          p={5}
          sx={{ zIndex: 5, borderRadius: 20 }}
          onClick={() => {
            handleExistingImages.openDeleteConfirmation(image.id, index);
            deleteImage(index);
          }}
        >
          <IconX style={{ color: "white" }} />
        </ActionIcon>
        <Image
          key={index}
          height={120}
          width={120}
          fit="contain"
          src={image_url + image.image}
          imageProps={{ onLoad: () => URL.revokeObjectURL(image.image) }}
        />
      </Box>
    );
  });

  const handleExistingImages = {
    openDeleteConfirmation: (id, index) => {
      openConfirmModal({
        title: "Please confirm delete image",
        children: <Text size="sm">Are you sure you want to delete this image ?</Text>,
        labels: { confirm: "Delete Image", cancel: "Cancel" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          handleExistingImages.deleteImage(id, index);
        },
        confirmProps: { color: "red" },
      });
    },

    deleteImage: async (id, index) => {
      setLoading(true);
      await products_api.deleteImage(id).then(res => {
        console.log(res);
        setLoading(false);
        if (res.success) {
          setExistingProductImages(
            produce(draft => {
              draft.splice(index, 1);
            })
          );
        } else {
          showErrorToast({
            title: "Error",
            message: "Error while deleting images",
          });
        }
      });
    },
  };

  const handleEdit = {
    fillValues: data => {
      data.specification && setSpecifications(JSON.parse(data.specification) || []);
      setExistingProductImages(data.product_image);
      reset({
        product_code: data.product_code,
        hsn_code: data.hsn_code,
        title: data.title,
        category_id: data.category?.id || "",
        sub_category_id: data.sub_category?.id || "",
        short_description: data.short_description,
        long_description: data.long_description,

        delivery_charges: data.delivery_charges || "",
        cod: data.cod,
        verified: data.verified,
        assured: data.assured,
        deals: data.deals,
        featured: data.featured,

        height: data.height ?? "",
        length: data.length || "",
        breadth: data.breadth || "",
        weight: data.weight || "",

        tax: data.tax || "",

        mrp: data.mrp || "",
        selling_price: data.selling_price || "",

        city_group: data.city_group_id,
        city_id: data.city?.id || "",

        in_stock:data?.in_stock
      });

      data.city && handleCityChange(data.city.id);
      data.category && handleCategoryChange(data.category.id);
    },
    submit: async values => {
      // 1. Upload images if selected
      if (files.length) {
        setLoading(true);
        const formData = new FormData();
        formData.append("product_id", searchParams.get("id"));
        for (let item of files) {
          formData.append("image", item);
        }
        await products_api.uploadImages(formData).then(res => {
          setLoading(false);
          if (!res.success) {
            showErrorToast({
              title: "Error while uploading Images",
              message: res.message,
            });
          }
        });
      }

      // 2. Change data
      const formData = constructFormData(values);
      products_api.edit(formData).then(res => {
        setLoading(false);
        console.log(res);
        if (res.success) {
          showSuccessToast({
            title: "Success",
            message: "Product successfully edited.",
          });
          navigate("/products");
        } else {
          showErrorToast({
            title: "Error",
            message: res.message,
          });
        }
      });
    },
  };

  const submitForm = values => {
    console.log("submit triggered");
    isEditing ? handleEdit.submit(values) : addProduct(values);
  };

  const constructFormData = values => {
    const form_data = new FormData();

    form_data.append("title", values.title);
    form_data.append("product_code", values.product_code);
    form_data.append("hsn_code", values.hsn_code);
    form_data.append("category_id", values.category_id);
    form_data.append("sub_category_id", values.sub_category_id);
    form_data.append("short_description", values.short_description);
    form_data.append("long_description", values.long_description);

    form_data.append("thumbnail", values.thumbnail);

    form_data.append("length", values.length);
    form_data.append("breadth", values.breadth);
    form_data.append("height", values.height);
    form_data.append("weight", values.weight);
    form_data.append("delivery_charges", values.delivery_charges);
    form_data.append("cod", values.cod ? "True" : "False");

    form_data.append("tax", values.tax);
    form_data.append("mrp", values.mrp);
    form_data.append("selling_price", values.selling_price);

    form_data.append("city_id", values.city_id);

    form_data.append("active", values.active ? "True" : "False");
    form_data.append("in_stock", values.in_stock ? "True" : "False");

    form_data.append("assured", values.assured ? "True" : "False");
    form_data.append("deals", values.deals ? "True" : "False");
    form_data.append("featured", values.featured ? "True" : "False");
    form_data.append("verified", values.verified ? "True" : "False");

    form_data.append("specification", JSON.stringify(specifications));

    if (isEditing) {
      form_data.append("product_id", searchParams.get("id"));
    }

    if (!isEditing) {
      for (let file of files) {
        form_data.append("image", file);
      }
    }

    return form_data;
  };

  const addProduct = async values => {
    setLoading(true);

    const formData = constructFormData(values);

    products_api.add(formData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        showSuccessToast({
          title: "Success",
          message: "Product successfully added.",
        });
        navigate("/products");
      } else {
        showErrorToast({
          title: "Error",
          message: res.message,
        });
      }
    });
  };

  // EDIT FUNCTIONS
  const edit_data = useQuery(
    ["product_data", searchParams.get("id")],
    () => products_api.get_by_id(searchParams.get("id")),
    {
      onSuccess: data => {
        console.log('qqq-0008',data)
        handleEdit.fillValues(data.data);
      },
      enabled: isEditing && categories.isSuccess,
      // staleTime: Infinity,
    }
  );

  return (
    <div>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box px={isMobileScreen ? 0 : 25} py={isMobileScreen ? 15 : 25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  {isEditing ? "Edit Product" : "Add New Product"}
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Master",
                    },
                    {
                      text: "Products",
                      link: "/master/products",
                    },
                    {
                      text: isEditing ? "Edit Product" : "Add Product",
                    },
                  ]}
                />
              </Box>
            </Flex>
          </Box>
        </Container>
      )}

      <Container fluid mt={10} mb={30}>
        <form
          onError={() => {
            console.log(errors);
          }}
          onSubmit={handleSubmit(values => {
            console.log(values);
            console.log("gey");
            submitForm(values);
            // submitForm(values);
          })}
        >
          <Box px={isMobileScreen ? 5 : 20}>
            {/* Starts :: Form */}
            <Paper
              mb={20}
              sx={theme => ({
                border: "1px solid #eee",
                borderRadius: 2,
              })}
            >
              <Box
                sx={theme => ({
                  borderBottom: `1px solid ${theme.colors.brand[0]}`,
                  borderRadius: 2,
                })}
              >
                <Flex px={28} py={14} align={"center"}>
                  <Text opacity={0.5} tt={"uppercase"} fw={600} size={"12px"}>
                    Basic Details
                  </Text>
                </Flex>
              </Box>
              <Box p={20} pt={10}>
                <Grid m={0} p={0}>
                  <Col sm={4}>
                    <Controller
                      name="product_code"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label="Product Code"
                          placeholder="Enter Product code"
                          error={errors.product_code?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={4}>
                    <Controller
                      name="hsn_code"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label="HSN / SAC code"
                          placeholder="Enter HSN / SAC code"
                          error={errors.hsn_code?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={4}>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label="Product title"
                          placeholder="Enter Product Title"
                          error={errors.title?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={6}>
                    <Controller
                      name="thumbnail"
                      control={control}
                      render={({ field }) => (
                        <FileInput
                          accept=".jpg, .jpeg, .png"
                          icon={<IconFileUpload />}
                          label={
                            <>
                              Product Thumbnail (
                              {isEditing && (
                                <a
                                  href={edit_data.data?.data?.thumbnail}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View Existing
                                </a>
                              )}
                              )
                            </>
                          }
                          placeholder="Upload Product thumbnail"
                          error={errors.thumbnail?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={6}>
                    <Controller
                      name="category_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={productCategories}
                          label="Product Category"
                          placeholder="Select Product Category"
                          error={errors.category_id?.message}
                          {...field}
                          onChange={e => {
                            handleCategoryChange(e);
                          }}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={6}>
                    <Controller
                      name="sub_category_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          disabled={!productSubCategories.length}
                          data={productSubCategories}
                          label="Product sub category"
                          placeholder="Select Product sub cateogry"
                          error={errors.sub_category_id?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={12}>
                    <Controller
                      name="short_description"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label="Product Short Description"
                          placeholder="Enter Product Short Description"
                          error={errors.short_description?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <Controller
                      name="long_description"
                      control={control}
                      render={({ field }) => (
                        <Textarea
                          minRows={8}
                          label="Product Long Description"
                          placeholder="Enter Product Long Description"
                          error={errors.long_description?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>

                  <Col span={12}>
                    <Text size={"14px"} fw={500} mb={5}>
                      Product Images
                    </Text>
                    <Dropzone
                      className={
                        existingProductImages.length === max_images ? classes.disabled : ""
                      }
                      disabled={existingProductImages.length === max_images}
                      aria-disabled={max_images - existingProductImages.length === 0}
                      onDrop={files => {
                        setDropzoneErrorMessage(null);
                        setFiles(files);
                      }}
                      onReject={files => {
                        console.log("rejected files", files);
                        setDropzoneErrorMessage(files[0].errors[0].message);
                      }}
                      maxSize={3 * 1024 ** 2}
                      accept={["image/png", "image/jpeg", "image/jpg"]}
                      maxFiles={isEditing ? max_images - existingProductImages.length : max_images}
                    >
                      <Group
                        position="center"
                        spacing="xl"
                        style={{ minHeight: 120, pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={
                              theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
                            }
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          <IconPhoto size="3.2rem" stroke={1.5} />
                        </Dropzone.Idle>

                        <div>
                          <Text size="md" inline>
                            Drag images here or click to select files
                          </Text>
                          { }
                          <Text size="sm" color="dimmed" inline mt={7}>
                            {isEditing
                              ? `Attach upto ${max_images - existingProductImages.length
                              } product images, each file should not exceed 3 MB`
                              : `Attach upto ${max_images} product images, each file should not exceed 3 MB`}
                          </Text>
                        </div>
                      </Group>
                    </Dropzone>
                    <Text color={"red"} size="14px">
                      {dropzoneErrorMessage}
                    </Text>
                    <SimpleGrid
                      cols={9}
                      breakpoints={[{ maxWidth: "sm", cols: 2 }]}
                      mt={previews.length > 0 ? "xl" : 0}
                    >
                      {previews}
                    </SimpleGrid>
                    {isEditing && (
                      <Paper bg={"#eee"} mt={10} p={10}>
                        <Text size={"14px"} fw={"600"}>
                          Existing Product Images
                        </Text>

                        <Text size={"12px"}>
                          {existingProductImages.length === max_images &&
                            `Maximum ${max_images} images are allowed, you can delete existing image to upload new ones.`}
                        </Text>

                        <hr />
                        <SimpleGrid mt={10} cols={9} breakpoints={[{ maxWidth: "sm", cols: 2 }]}>
                          {exitingImages}
                        </SimpleGrid>
                      </Paper>
                    )}
                  </Col>

                  <Col span={12}>
                    <Text size={"14px"} fw={500} mb={5}>
                      Product Specifications
                    </Text>
                    <Table withColumnBorders withBorder>
                      <thead>
                        <tr>
                          <th style={{ fontWeight: 500 }} width={220}>
                            Title
                          </th>
                          <th style={{ fontWeight: 500 }}>Description</th>

                          <th style={{ fontWeight: 500 }} width={100}>
                            <Flex justify={"space-between"} align="center">
                              <Button
                                variant={"light"}
                                leftIcon={<IconPlus />}
                                onClick={handleSpecifications.add}
                              >
                                Add
                              </Button>
                            </Flex>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {specifications.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ fontWeight: 500 }}>
                                <TextInput
                                  name="title"
                                  value={item.title}
                                  onChange={e => {
                                    handleSpecifications.update(specifications.indexOf(item), e);
                                  }}
                                  placeholder="Enter Title here.."
                                />
                              </td>
                              <td style={{ fontWeight: 500 }}>
                                <TextInput
                                  name="description"
                                  value={item.description}
                                  onChange={e => {
                                    handleSpecifications.update(specifications.indexOf(item), e);
                                  }}
                                  placeholder="Enter Description here.."
                                />
                              </td>
                              <td style={{ fontWeight: 500 }}>
                                <Flex justify={"space-around"} align="center">
                                  <ActionIcon onClick={handleSpecifications.add} variant={"light"}>
                                    <IconPlus />
                                  </ActionIcon>
                                  <ActionIcon
                                    onClick={() => handleSpecifications.delete(index)}
                                    variant={"light"}
                                    color="red"
                                  >
                                    <IconTrash />
                                  </ActionIcon>
                                </Flex>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Grid>
              </Box>
            </Paper>

            {/* Toggles */}
            <Paper
              mb={20}
              pb={10}
              sx={theme => ({
                border: "1px solid #eee",
                borderRadius: 2,
              })}
            >
              <Box
                sx={theme => ({
                  borderBottom: `1px solid ${theme.colors.brand[0]}`,
                  borderRadius: 2,
                })}
              >
                <Text opacity={0.5} tt={"uppercase"} fw={600} px={28} py={14} size={"12px"}>
                  Product Origin
                </Text>
              </Box>
              <Box p={20} pt={10}>
                <Grid m={0} p={0}>
                  <Col sm={6}>
                    <Controller
                      name="city_group"
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={cityGroups}
                          label="City Group"
                          placeholder="Select City Group"
                          error={errors.city_group?.message}
                          {...field}
                          onChange={e => {
                            handleCityChange(e);
                          }}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={6}>
                    <Controller
                      name="city_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          disabled={!cities.length}
                          data={cities}
                          label="City"
                          placeholder="Select City"
                          error={errors.city_id?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                </Grid>
              </Box>
            </Paper>

            {/* Status Toggles */}
            <Paper
              mb={20}
              pb={10}
              sx={theme => ({
                border: "1px solid #eee",
                borderRadius: 2,
              })}
            >
              <Box
                sx={theme => ({
                  borderBottom: `1px solid ${theme.colors.brand[0]}`,
                  borderRadius: 2,
                })}
              >
                <Text opacity={0.5} tt={"uppercase"} fw={600} px={28} py={14} size={"12px"}>
                  Status
                </Text>
              </Box>
              <Box p={20} pt={10}>
                <Grid m={0} p={0}>
                  <Col sm={6}>
                    <Controller
                      name="active"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          label="Active"
                          description="Product will stop showing everywhere"
                          error={errors.active?.message}
                          thumbIcon={
                            field.value ? (
                              <IconCheck
                                size="0.8rem"
                                color={theme.colors.teal[theme.fn.primaryShade()]}
                                stroke={3}
                              />
                            ) : (
                              <IconX
                                size="0.8rem"
                                color={theme.colors.red[theme.fn.primaryShade()]}
                                stroke={3}
                              />
                            )
                          }
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Controller
                      name="in_stock"
                      control={control}
                      render={({ field }) =>{
                        console.log('qqq-1123',field)
                        return (
                          <Switch
                            checked={field.value}
                            label="In stock"
                            description="For E-commerce website"
                            error={errors.in_stock?.message}
                            thumbIcon={
                              field.value ? (
                                <IconCheck
                                  size="0.8rem"
                                  color={theme.colors.teal[theme.fn.primaryShade()]}
                                  stroke={3}
                                />
                              ) : (
                                <IconX
                                  size="0.8rem"
                                  color={theme.colors.red[theme.fn.primaryShade()]}
                                  stroke={3}
                                />
                              )
                            }
                            {...field}
                          />
                        )
                      } }
                    />
                  </Col>
                </Grid>
              </Box>
            </Paper>

            {/* pricing details */}
            <Paper
              mb={20}
              pb={10}
              sx={theme => ({
                border: "1px solid #eee",
                borderRadius: 2,
              })}
            >
              <Box
                sx={theme => ({
                  borderBottom: `1px solid ${theme.colors.brand[0]}`,
                  borderRadius: 2,
                })}
              >
                <Text opacity={0.5} tt={"uppercase"} fw={600} px={28} py={14} size={"12px"}>
                  Pricing Details
                </Text>
              </Box>
              <Box p={20} pt={10}>
                <Grid m={0} p={0}>
                  <Col sm={4}>
                    <Controller
                      name="mrp"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          label="MRP"
                          placeholder="Enter MRP"
                          error={errors.mrp?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={4}>
                    <Controller
                      name="selling_price"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          label="Selling Price"
                          placeholder="Enter Selling Price"
                          error={errors.selling_price?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={4}>
                    <Controller
                      name="tax"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          max={100}
                          label="Tax percentage ( % )"
                          placeholder="Enter Tax ercentage"
                          error={errors.tax?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                </Grid>
              </Box>
            </Paper>

            {/* Shipping information */}
            <Paper
              mb={20}
              pb={10}
              sx={theme => ({
                border: "1px solid #eee",
                borderRadius: 2,
              })}
            >
              <Box
                sx={theme => ({
                  borderBottom: `1px solid ${theme.colors.brand[0]}`,
                  borderRadius: 2,
                })}
              >
                <Text opacity={0.5} tt={"uppercase"} fw={600} px={28} py={14} size={"12px"}>
                  Shipping & Delivery Details
                </Text>
              </Box>
              <Box p={20} pt={10}>
                <Grid m={0} p={0}>
                  <Col sm={3}>
                    <Controller
                      name="length"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          label="Product Length (cm)"
                          placeholder="Enter Product Length"
                          error={errors.length?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Controller
                      name="breadth"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          label="Product Breadth (cm)"
                          placeholder="Enter Product Breadth"
                          error={errors.breadth?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Controller
                      name="height"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          label="Product Height (cm)"
                          placeholder="Enter Product Height"
                          error={errors.height?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Controller
                      name="weight"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          label="Product Weight (kg)"
                          placeholder="Enter Product Weight"
                          error={errors.weight?.message}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Controller
                      name="delivery_charges"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          type="number"
                          description="Leave 0 if you want to set free delivery"
                          label="Delivery Charge (optional)"
                          placeholder="Enter Delivery Charge"
                          error={errors.delivery_charges?.message}
                          {...field}
                          inputWrapperOrder={["label", "input", "description", "error"]}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Controller
                      name="cod"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value}
                          pt={30}
                          label="Cash On Delivery"
                          error={errors.cod?.message}
                          thumbIcon={
                            field.value ? (
                              <IconCheck
                                size="0.8rem"
                                color={theme.colors.teal[theme.fn.primaryShade()]}
                                stroke={3}
                              />
                            ) : (
                              <IconX
                                size="0.8rem"
                                color={theme.colors.red[theme.fn.primaryShade()]}
                                stroke={3}
                              />
                            )
                          }
                          {...field}
                        />
                      )}
                    />
                  </Col>
                </Grid>
              </Box>
            </Paper>

            {/* E-com frontend information */}
            <Paper
              mb={20}
              pb={10}
              sx={theme => ({
                border: "1px solid #eee",
                borderRadius: 2,
              })}
            >
              <Box
                sx={theme => ({
                  borderBottom: `1px solid ${theme.colors.brand[0]}`,
                  borderRadius: 2,
                })}
              >
                <Text opacity={0.5} tt={"uppercase"} fw={600} px={28} py={14} size={"12px"}>
                  Product Labels
                  <Text tt={"none"} fw={400} size={"xs"} variant="dimmed">
                    For e-commerce website purpose.
                  </Text>
                </Text>
              </Box>
              <Box p={20} pt={10}>
                <Grid m={0} p={0}>
                  <Col sm={2}>
                    <Controller
                      name="verified"
                      control={control}
                      render={({ field }) => (
                        <Checkbox checked={field.value} label="Verified" {...field} />
                      )}
                    />
                  </Col>
                  <Col sm={2}>
                    <Controller
                      name="assured"
                      control={control}
                      render={({ field }) => (
                        <Checkbox checked={field.value} label="Assured" {...field} />
                      )}
                    />
                  </Col>
                  <Col sm={2}>
                    <Controller
                      name="deals"
                      control={control}
                      render={({ field }) => (
                        <Checkbox checked={field.value} label="Deals" {...field} />
                      )}
                    />
                  </Col>
                  <Col sm={2}>
                    <Controller
                      name="featured"
                      control={control}
                      render={({ field }) => (
                        <Checkbox checked={field.value} label="Featured" {...field} />
                      )}
                    />
                  </Col>
                </Grid>
              </Box>
            </Paper>

            <Button
              type="submit"
              w={"100%"}
              loading={loading}
              size="md"
              rightIcon={<IconArrowRight />}
              onClick={() => {
                console.log(errors);
              }}
            >
              Submit
            </Button>
            {/* Ends :: Form */}
          </Box>
        </form>
      </Container>
    </div>
  );
}

export default AddProductForm;
