import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Col,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Image,
  Modal,
  Select,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconEdit,
  IconEye,
  IconFrame,
  IconLocation,
  IconMenu,
  IconMenu2,
  IconPhoto,
  IconPictureInPicture,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import AppBreadcrumb from "components/AppBreadcrumb";
import FloatingMenu from "components/FloatingMenu";
import { image_url } from "config";
import { MantineReactTable } from "mantine-react-table";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import {
  api_addBanner,
  api_deleteBanner,
  api_editBannerImage,
  api_editBannerLocations,
  api_editBannerProduct,
  api_getBanners,
} from "./banner.service";
import { getAlteredProduct } from "./helperFunctions";
import { products_api } from "../products/products.service";

export default function Banners() {
  const theme = useMantineTheme();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [editingId, setEditingId] = useState(null);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [isEditingLocations, setIsEditingLocations] = useState(false);
  const [modalOpenedView, setModalOpenedView] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [viewImage, setViewImage] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  // ENDS : States

  // Fetching banners
  const banners = useQuery("banners", api_getBanners, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setColumnsData(data.data);
    },
  });

  const products = useQuery("products_list", products_api.get, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setProductsData(getAlteredProduct(data.data));
    },
  });

  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete banner",
      children: (
        <Text size="sm">
          Are you sure you want to delete this banner data ? This action can't be undone.
        </Text>
      ),
      labels: { confirm: "Delete Banner", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await deleteBanner(id),
      confirmProps: { color: "red" },
    });
  };

  const deleteBanner = async id => {
    await api_deleteBanner(id)
      .then(res => {
        if (res.success) {
          showSuccessToast({ title: "Deleted Banner", message: res.message });
          banners.refetch();
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      })
      .catch(e => {
        showErrorToast({ title: "Error", message: e.message });
      });
  };

  const columns = useMemo(
    () => [
      {
        header: "Banner",
        accessorKey: "title", //simple accessorKey pointing to flat data
        size: "auto",
        Cell: ({ renderedCellValue, row }) => (
          <Flex align={"center"}>
            <img
              src={
                row.original.image
                  ? image_url + row.original.image
                  : "https://universalele.websites.co.in/obaju-turquoise/img/product-placeholder.png"
              }
              width={98}
              height={30}
              style={{
                borderRadius: 3,
                border: "2px solid #eee",
                objectFit: "contain",
              }}
              alt=""
            />
            <Text ml={10}> {renderedCellValue} </Text>
          </Flex>
        ),
      },
      {
        header: "Home",
        accessorKey: "home_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.home_banner} />,
      },
      {
        header: "Mid",
        accessorKey: "mid_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.mid_banner} />,
      },
      {
        header: "Product",
        accessorKey: "product_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.product_banner} />,
      },
      {
        header: "Bottom",
        accessorKey: "bottom_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.bottom_banner} />,
      },
      {
        header: "Vendor",
        accessorKey: "vendor_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.vendor_banner} />,
      },
      {
        header: "Affliator",
        accessorKey: "affliator_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.affliator_banner} />,
      },
      {
        header: "Offer",
        accessorKey: "offer_banner",
        size: "auto",
        Cell: ({ renderedCellValue, row }) => <Checkbox checked={row.original.offer_banner} />,
      },
    ],
    []
  );

  const returnForm = useForm({
    validateInputOnBlur: true,
    shouldUnregister: false,
    initialValues: {
      name: "",
      image: null,
      // is_top: false,
      // is_middle: false,
      // is_bottom: false,
      is_home_banner: false,
      is_mid_banner: false,
      is_product_banner: false,
      is_bottom_banner: false,
      is_vendor_banner: false,
      is_affliator_banner: false,
      is_offer_banner: false,
      product: "",
    },
    validate: {
      name: value =>
        isEditingImage || isEditingLocations || isEditingProduct
          ? null
          : value
          ? null
          : "Name is required",
      image: value =>
        isEditingLocations || isEditingProduct ? null : value ? null : "Image is required",
      product: value => (isEditingProduct ? (value ? null : "Product is required") : null),
    },
  });

  const initEditingForm = row => {
    let values = row.original;
    console.log(values);

    returnForm.setValues({
      name: values.name ? values.name : "",
      image: null,
      is_home_banner: values.home_banner,
      is_mid_banner: values.mid_banner,
      is_product_banner: values.product_banner,
      is_bottom_banner: values.bottom_banner,
      is_vendor_banner: values.vendor_banner,
      is_affliator_banner: values.affliator_banner,
      is_offer_banner: values.offer_banner,
    });
  };

  const addBanner = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("image_file", values.image);
    formData.append("home_banner", values.is_home_banner ? "True" : "False");
    formData.append("mid_banner", values.is_mid_banner ? "True" : "False");
    formData.append("product_banner", values.is_product_banner ? "True" : "False");
    formData.append("bottom_banner", values.is_bottom_banner ? "True" : "False");
    formData.append("vendor_banner", values.is_vendor_banner ? "True" : "False");
    formData.append("affliator_banner", values.is_affliator_banner ? "True" : "False");
    formData.append("offer_banner", values.is_offer_banner ? "True" : "False");

    await api_addBanner(formData).then(res => {
      setLoading(false);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setLoginError(false);
        setModalOpened(false);
        setIsEditingImage(false);
        setIsEditingLocations(false);
        banners.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  const editBannerImage = async values => {
    setLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("id", editingId);
    formData.append("image", values.image);

    await api_editBannerImage(formData).then(res => {
      setLoading(false);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setLoginError(false);
        setModalOpened(false);
        setIsEditingImage(false);
        setIsEditingLocations(false);
        banners.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  const editBannerLocations = async values => {
    setLoading(true);
    console.log(values);
    console.log(editingId);

    const payload = {
      id: parseInt(editingId),
      home_banner: values.is_home_banner,
      mid_banner: values.is_mid_banner,
      product_banner: values.is_product_banner,
      bottom_banner: values.is_bottom_banner,
      vendor_banner: values.is_vendor_banner,
      affliator_banner: values.is_affliator_banner,
      offer_banner: values.is_offer_banner,
    };

    await api_editBannerLocations(payload).then(res => {
      setLoading(false);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setLoginError(false);
        setModalOpened(false);
        setIsEditingImage(false);
        setIsEditingLocations(false);
        banners.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  const editBannerProduct = async values => {
    setLoading(true);
    console.log(values);

    const payload = {
      id: parseInt(editingId),
    };

    await api_editBannerProduct(parseInt(values.product), payload).then(res => {
      setLoading(false);
      if (res.success) {
        showSuccessToast({ title: "Success", message: res.messge });
        setLoginError(false);
        setModalOpened(false);
        setIsEditingImage(false);
        setIsEditingLocations(false);
        setIsEditingProduct(false);
        banners.refetch();
      } else {
        setLoginErrorMessage(res.message);
        setLoginError(true);
      }
    });
  };

  return (
    <>
      {isMobileScreen ? (
        <></>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Banners Master
                </Text>
                <AppBreadcrumb />
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    returnForm.reset();
                    setModalOpened(true);
                  }}
                >
                  <IconPlus style={{ width: "20px" }} />
                  <Text ml={10}> Add Banner </Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}
      <Container fluid mt={10} mb={30}>
        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box>
            <MantineReactTable
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Banners List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Banner">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        setViewImage(row.original.image);
                        setEditingId(row.original.id);
                        setModalOpenedView(true);
                      }}
                    >
                      <IconEye style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Edit Banner Image">
                    <ActionIcon
                      ml={10}
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        //initEditingCustomer(row);
                        returnForm.reset();
                        initEditingForm(row);
                        setEditingId(row.original.id);
                        setIsEditingImage(true);
                        setModalOpened(true);
                      }}
                    >
                      <IconPhoto style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Edit Banner Locations">
                    <ActionIcon
                      ml={10}
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        //initEditingCustomer(row);
                        returnForm.reset();
                        initEditingForm(row);
                        setEditingId(row.original.id);
                        setIsEditingLocations(true);
                        setModalOpened(true);
                      }}
                    >
                      <IconMenu2 style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Edit Banner Product">
                    <ActionIcon
                      ml={10}
                      sx={theme => ({ color: theme.colors.brand[7] })}
                      onClick={() => {
                        //initEditingCustomer(row);
                        returnForm.reset();
                        initEditingForm(row);
                        setEditingId(row.original.id);
                        setIsEditingProduct(true);
                        setModalOpened(true);
                      }}
                    >
                      <IconEdit style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Delete Banner">
                    <ActionIcon
                      sx={theme => ({ color: theme.colors.red[6] })}
                      ml={10}
                      onClick={() => {
                        openDeleteConfirmation(row.original.id);
                      }}
                    >
                      <IconTrash style={{ width: 20 }} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              )}
              initialState={{ density: "xs" }}
              state={{ isLoading: banners.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={columnsData || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      <Modal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
          setIsEditingImage(false);
          setIsEditingLocations(false);
          setIsEditingProduct(false);
          setEditingId(null);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>
              {isEditingImage
                ? "Edit Banner Image"
                : isEditingLocations
                ? "Edit Banner Locations"
                : isEditingProduct
                ? "Edit Banner Product"
                : "Add Banner"}
            </Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <form
              onSubmit={returnForm.onSubmit(values => {
                console.log(values);
                isEditingImage
                  ? editBannerImage(values)
                  : isEditingLocations
                  ? editBannerLocations(values)
                  : isEditingProduct
                  ? editBannerProduct(values)
                  : addBanner(values);
              })}
            >
              <Grid>
                {isEditingImage || isEditingLocations || isEditingProduct ? (
                  <></>
                ) : (
                  <Col sm={6}>
                    <TextInput
                      label="Banner Name"
                      placeholder="Enter Banner Name"
                      {...returnForm.getInputProps("name")}
                    />
                  </Col>
                )}
                {isEditingLocations || isEditingProduct ? (
                  <></>
                ) : (
                  <Col sm={6}>
                    <FileInput
                      {...returnForm.getInputProps("image")}
                      label="Banner Image"
                      placeholder="Select Image"
                    />
                  </Col>
                )}
                {/* <Col sm={3}>
                  <Checkbox
                    label="Top"
                    {...returnForm.getInputProps("is_top", { type: "checkbox" })}
                  />
                </Col>
                <Col sm={3}>
                  <Checkbox
                    label="Middle"
                    {...returnForm.getInputProps("is_middle", { type: "checkbox" })}
                  />
                </Col>
                <Col sm={3}>
                  <Checkbox
                    label="Bottom"
                    {...returnForm.getInputProps("is_bottom", { type: "checkbox" })}
                  />
                </Col> */}
                {isEditingImage || isEditingProduct ? (
                  <></>
                ) : (
                  <>
                    <Col sm={3}>
                      <Checkbox
                        label="Home Banner"
                        {...returnForm.getInputProps("is_home_banner", { type: "checkbox" })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Checkbox
                        label="Mid Banner"
                        {...returnForm.getInputProps("is_mid_banner", { type: "checkbox" })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Checkbox
                        label="Product Banner"
                        {...returnForm.getInputProps("is_product_banner", { type: "checkbox" })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Checkbox
                        label="Bottom Banner"
                        {...returnForm.getInputProps("is_bottom_banner", { type: "checkbox" })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Checkbox
                        label="Vendor Banner"
                        {...returnForm.getInputProps("is_vendor_banner", { type: "checkbox" })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Checkbox
                        label="Affliator Banner"
                        {...returnForm.getInputProps("is_affliator_banner", { type: "checkbox" })}
                      />
                    </Col>
                    <Col sm={3}>
                      <Checkbox
                        label="Offer Banner"
                        {...returnForm.getInputProps("is_offer_banner", { type: "checkbox" })}
                      />
                    </Col>
                  </>
                )}
                {isEditingProduct ? (
                  <Col sm={12}>
                    <Select
                      data={productsData || [{}]}
                      dropdownPosition="bottom"
                      searchable
                      nothingFound="No product found"
                      label="Product"
                      placeholder="Enter Product"
                      {...returnForm.getInputProps("product")}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <Col span={12} my={10}>
                  <Flex h={"100%"} direction={"column"} justify="end" align={"center"}>
                    <Button w={"100%"} type="submit">
                      {isEditingImage
                        ? "Edit Banner Image"
                        : isEditingLocations
                        ? "Edit Banner Locations"
                        : isEditingProduct
                        ? "Edit Banner Product"
                        : "Add Banner"}
                    </Button>
                  </Flex>
                </Col>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      {/* View image */}
      <Modal
        opened={modalOpenedView}
        onClose={() => {
          setModalOpenedView(false);
          setIsEditingImage(false);
          setIsEditingLocations(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>Banner</Text>
          </Box>
        }
        size={"800px"}
        centered
      >
        <Box px={10}>
          <Divider mb={20} />
          <Box>
            <Image
              mx="auto"
              src={
                viewImage
                  ? image_url + viewImage
                  : "https://universalele.websites.co.in/obaju-turquoise/img/product-placeholder.png"
              }
              alt=""
            />
          </Box>
        </Box>
      </Modal>

      {isMobileScreen ? (
        <FloatingMenu
          m={5}
          right
          size={50}
          onClick={() => {
            returnForm.reset();
            setModalOpened(true);
          }}
        >
          <IconPlus color="white" />
        </FloatingMenu>
      ) : (
        <></>
      )}
    </>
  );
}
