import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconBox,
  IconBoxSeam,
  IconDownload,
  IconEdit,
  IconEye,
  IconPrinter,
  IconSquareRoundedCheck,
  IconSquareRoundedX,
  IconTransferIn,
} from "@tabler/icons";
import { IconEyeFilled } from "@tabler/icons-react";
import AppPageHeader from "components/AppPageHeader";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment";
import { getCounterId, hasPermission, isCounterAdmin } from "pages/Auth/permissionGuard";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";

import StockTransferDetailsModal from "./StockTransferDetailsModal";
import { stock_apis } from "./stock-transfer.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";

export default function StockTransfer() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [orderDetailsModalVisible, setOrderDetailsModalVisible] = useState(false);
  const [selectedStockTransferId, setSelectedStockTransferId] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filename, setFilename] = useState("file");

  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId() : "");
  const [countersList, setCountersList] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
    },
  });

  const stockTransferMutation = useMutation({
    mutationFn: data => stock_apis.change_transfer_status(data),
    onSuccess: data => {
      console.log(data);
      if (data.data?.success) {
        showSuccessToast({
          title: "Success",
          message: "Changed status.",
        });
        queryClient.invalidateQueries(["stock_list_data"]);
        stock_data.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: data.data?.message,
        });
      }
    },
    onError: () => {
      showErrorToast({
        title: "Error",
        message: "Internal server error.",
      });
    },
  });

  // const stock_data = useQuery("stock_list_data", stock_apis.get_stock_list);

  const stock_data = useQuery({
    queryKey: ["stock_list_data", pagination.pageIndex, pagination.pageSize, counter, columnFilters], // Include filters in queryKey
    queryFn: async () => {
      if (columnFilters.length > 0) {
        return printFilter(false); // Fetch filtered data when table filters are applied
      } else {
        return stock_apis.get_stock_list(pagination.pageSize, pagination.pageIndex + 1, counter);
      }
    },
    keepPreviousData: true,
  });
  

  // console.log("stock_list_data", stock_data.data);

  const openAcceptStockConfirmation = id => {
    openConfirmModal({
      size: "500px",
      centered: true,
      title: "Are you sure you want to accept this stock transfer ?",
      children: <Text size="sm">This cannot be undone.</Text>,
      labels: { confirm: "Accept Stock Transfer", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await acceptStockTransfer(id),
      confirmProps: { color: "green" },
      position: "center",
    });
  };

  const openRejectStockConfirmation = id => {
    openConfirmModal({
      centered: true,
      title: "Are you sure you want to reject this stock transfer ?",
      children: <Text size="sm">This cannot be undone.</Text>,
      labels: { confirm: "Reject Stock Transfer", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await rejectStockTransfer(id),
      confirmProps: { color: "red" },
    });
  };

  const acceptStockTransfer = id => {
    stockTransferMutation.mutate({
      stock_list_id: id,
      status: "accept",
    });
  };

  const rejectStockTransfer = id => {
    stockTransferMutation.mutate({
      stock_list_id: id,
      status: "reject",
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: originalRow => new Date(originalRow.created_at),
        id: "created_at",
        header: "Date",
        accessorKey: "created_at",
        size: 50,
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        filterVariant: "date-range",
      },
      {
        header: "Transferred From",
        accessorKey: "from_counter_name",
      },
      {
        header: "Recieved to",
        accessorKey: "to_counter_name",
      },
      {
        header: "Amount",
        accessorKey: "amount",
        filterVariant: "range",
        Cell: ({ cell }) => <span> ₹ {cell.getValue()}</span>,
      },

      {
        header: "Status",
        accessorKey: "status",
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: ["created", "accepted"],
        },
        Cell: ({ cell }) => <Badge> {cell.getValue()}</Badge>,
      },
    ],
    []
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [3];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  // const printFilter = () => {
  //   const model = "StockList";

  //   const appendData = [
  //     { id: "created_at", append: "" },
  //     { id: "name", append: "from_counter__", replace_id: "from_counter_name" },
  //     { id: "name", append: "to_counter__", replace_id: "to_counter_name" },
  //     { id: "amount", append: "" },
  //     { id: "status", append: "" },
  //   ];
  //   const custom = getCustomId(columns, columnFilters, appendData);
  //   const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
  //   const fields = getFields(custom?.columns);

  //   api_filter(model, filter, fields)
  //     .then(res => {
  //       if (res.success) {
  //         console.log(res.data);
  //         const head_content = getHeaders(columns);
  //         const body_content = getBodyContent(res.data, fields);
  //         const csv_content = getCsvContent(head_content, body_content, ["STOCK_TRANSFER"]);
  //         const columnTotalIndex = [3];
  //         const foot_content = getTotal(body_content, columnTotalIndex);
  //         setFilename(
  //           "STOCK_TRANSFER" +
  //             "_" +
  //             countersList?.find(e => e.id === counter)?.label +
  //             "_" +
  //             new Date().toLocaleDateString() +
  //             ".csv"
  //         );

  //         setCsv(csv_content);
  //         setHead(head_content);
  //         setBody(body_content);
  //         setFoot(foot_content);
  //       } else {
  //         showErrorToast({ title: "Error", message: res.message });
  //       }
  //     })
  //     .catch(e => {
  //       showErrorToast({ title: "Error", message: e.message });
  //     });
  // };

  const printFilter = async (forCsv = true) => {
    const model = "StockList";
    const appendData = [
      { id: "created_at", append: "" },
      { id: "name", append: "from_counter__", replace_id: "from_counter_name" },
      { id: "name", append: "to_counter__", replace_id: "to_counter_name" },
      { id: "amount", append: "" },
      { id: "status", append: "" },
    ];
  
    const custom = getCustomId(columns, columnFilters, appendData);
    const filterPayload = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);
  
    try {
      const res = await api_filter(model, filterPayload, fields,"StockListSerializer");
      if (res.success) {
        let body_content = getBodyContent(res.data, fields);
  
        if (forCsv) {
          // **CSV Export**
          const head_content = getHeaders(columns);
          const csv_content = getCsvContent(head_content, body_content, ["STOCK_TRANSFER"]);
          const columnTotalIndex = [3]; // Totals for the amount column
          const foot_content = getTotal(body_content, columnTotalIndex);
  
          setFilename(
            `STOCK_TRANSFER_${countersList?.find(e => e.id === counter)?.label}_${new Date().toLocaleDateString()}.csv`
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          // **Table Filtering**
          return { data: res.data };
        }
      } else {
        showErrorToast({ title: "Error", message: res.message });
        return { data: [] };
      }
    } catch (e) {
      showErrorToast({ title: "Error", message: e.message });
      return { data: [] };
    }
  };
  

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      <Container fluid>
        {isMobileScreen ? (
          <>
            <Select
              p={5}
              label="Counter"
              defaultValue={counter || null}
              placeholder="Select Counter"
              data={countersList || []}
              onChange={setCounter}
              clearable
              disabled={isCounterAdmin()}
            />
          </>
        ) : (
          <AppPageHeader
            title="Transfer Stock"
            button={
              hasPermission("add_counterstock") && (
                <>
                  <Box>
                    <Flex align={"end"} gap={10}>
                      <Button
                        onClick={() => {
                          setIsCsv(true);
                          printFilter();
                        }}
                        leftIcon={<IconDownload />}
                      >
                        CSV
                      </Button>
                      {/* <Button
                        leftIcon={<IconPrinter />}
                        onClick={() => {
                          print(true);
                        }}
                      >
                        Print
                      </Button> */}

                      <Button
                        leftIcon={<IconPrinter />}
                        onClick={() => {
                          printFilter();
                        }}
                      >
                        Print
                      </Button>
                      <>
                        {/* {!isCounterAdmin() && ( */}
                          <>
                            {hasPermission("add_counterstock") && (
                              <Button
                                leftIcon={<IconTransferIn size={18} />}
                                onClick={() => {
                                  navigate("add");
                                }}
                              >
                                Transfer Stock
                              </Button>
                            )}
                          </>
                        {/* )} */}
                      </>

                      <Select
                        label="Counter"
                        defaultValue={counter || null}
                        placeholder="Select Counter"
                        data={countersList || []}
                        onChange={setCounter}
                        clearable
                        disabled={isCounterAdmin()}
                      />
                    </Flex>
                  </Box>
                </>
              )
            }
          />
        )}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }} mt={10} mb={30}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={{pagination,columnFilters}} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable", striped: true }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => (
                <Flex>
                  <Tooltip label="View Transfer Details">
                    <ActionIcon
                      mr={10}
                      sx={theme => ({ color: theme.colors.brand[6] })}
                      onClick={() => {
                        setSelectedStockTransferId(row.original.id);
                        setOrderDetailsModalVisible(true);
                      }}
                    >
                      <IconBox style={{ width: 22 }} />
                    </ActionIcon>
                  </Tooltip>
                  {!isCounterAdmin() && (
                    <Tooltip label="Print Stock Transfer">
                      <ActionIcon
                        mr={10}
                        sx={theme => ({ color: theme.colors.brand[6] })}
                        onClick={() => {
                          navigate(`print/?id=${row.original.id}`);
                        }}
                      >
                        <IconPrinter style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                  {row.original.status === "created" && (
                    <>
                      {hasPermission("change_counterstock") && (
                        <>
                          {isCounterAdmin() && (
                            <>
                              <Tooltip label="Accept Stock Transfer" color={"green"}>
                                <ActionIcon
                                  sx={theme => ({ color: theme.colors.green[7] })}
                                  onClick={() => {
                                    openAcceptStockConfirmation(row.original.id);
                                  }}
                                  mr={4}
                                >
                                  <IconSquareRoundedCheck style={{ width: 22 }} />
                                </ActionIcon>
                              </Tooltip>
                              <Tooltip label="Reject Stock Tranfer" color={"red"}>
                                <ActionIcon
                                  sx={theme => ({ color: theme.colors.red[6] })}
                                  onClick={() => {
                                    openRejectStockConfirmation(row.original.id);
                                  }}
                                  mr={4}
                                >
                                  <IconSquareRoundedX style={{ width: 22 }} />
                                </ActionIcon>
                              </Tooltip>
                            </>
                          )}

                          {!isCounterAdmin() && (
                            <Tooltip label="Edit Stock Transfer">
                              <ActionIcon
                                mr={10}
                                sx={theme => ({ color: theme.colors.brand[6] })}
                                onClick={() => {
                                  setSelectedStockTransferId(row.original.id);
                                  setOrderDetailsModalVisible(true);
                                  navigate(`edit/?id=${row.original.id}`);
                                }}
                              >
                                <IconEdit style={{ width: 22 }} />
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Flex>
              )}
              initialState={{ density: "xs" }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={stock_data.data?.data || []}
              rowCount={stock_data.data?.count}
              enableStickyHeader
              enableGlobalFilter={false}
              //state={{
              //  isLoading: stock_data.isLoading || stock_data.isRefetching,
              //  pagination,
              //}}
              // onPaginationChange={setPagination}
              onPaginationChange={(updater) => {
                setPagination((prev) => {
                  const newPagination = typeof updater === "function" ? updater(prev) : updater;
                  return {
                    pageIndex: newPagination.pageIndex ?? prev.pageIndex, // ✅ Keep previous pageIndex if not changed
                    pageSize: newPagination.pageSize ?? prev.pageSize, // ✅ Keep previous pageSize if not changed
                  };
                });
              }}
              manualPagination
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      <StockTransferDetailsModal
        stockTransferId={selectedStockTransferId}
        opened={orderDetailsModalVisible}
        onClose={() => {
          setOrderDetailsModalVisible(false);
        }}
      />
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[3]}
          title="Stock Transfer"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {hasPermission("add_counterstock") && isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            ml={60}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              navigate("add");
            }}
          >
            <IconTransferIn color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
