export const hasPermission = permission => {
  let permissions = JSON.parse(localStorage.getItem("permissions"));
  if (permissions) {
    return permissions.includes(permission);
  } else {
    return false;
  }
};

export const isCounterAdmin = () => {
  const role = localStorage.getItem("role");
  if (role === "counter_admin") {
    return true;
  } else {
    return false;
  }
};

export const isManager = () => {
  const role = localStorage.getItem("role")
  if (role === "manager") {
    return true;
  } else {
    return false;
  }
};

export const getCounterId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const counter = user.user?.counter;
  // if (isCounterAdmin()) {
  //   return counter;
  // }
  return counter;

};
