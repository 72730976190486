import { Box, Container } from "@mantine/core";
import { Outlet } from "react-router";
import './index.css'

import AppHeader from "./components/AppHeader";

function App() {
  return (
    <div className="App">
      <AppHeader />
      <div className="outlet-container">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
