import { joiResolver } from "@hookform/resolvers/joi";
import {
  ActionIcon,
  Box,
  Button,
  Col,
  Container,
  Flex,
  Grid,
  Input,
  NumberInput,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useFocusTrap, useMediaQuery } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons";
import { IconCircleMinus } from "@tabler/icons-react";
import AppPageHeader from "components/AppPageHeader";
import produce from "immer";
import Joi from "joi";
import moment from "moment";
import { counters_api } from "pages/master/counter/counter.service";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "utilities/Toast";

import SelectProductModal from "./SelectProductModal";
import { stock_apis } from "./stock-transfer.service";

function AddStockTransfer() {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef(null);
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  const [selectProductModalOpened, setSelectProductModalOpened] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [counters, setCounters] = useState([]);
  const [products, setProducts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [render, setRender] = useState(false);
  const [wareHouseCounters, setWareHouseCounters] = useState([]);

  const emptyMessage = label => {
    return {
      "string.empty": `${label} is required.`,
      "number.base": `${label} is required.`,
    };
  };

  const validationSchema = Joi.object({
    from_counter: Joi.number().required().messages(emptyMessage("From")),
    to_counter: Joi.number().required().messages(emptyMessage("To")),
    date: Joi.required().messages(emptyMessage("Date")),
  });

  const getCounters = useQuery("counters_list", counters_api.get, {
    onSuccess: data => {
      console.log("counters list", data.data);
      let counters_list = data.data.map(e => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      const a = data.data.filter(e => e.warehouse);
      console.log(a);
      setWareHouseCounters(a);
      setCounters(counters_list);
      reset({
        from_counter: a[0]?.id,
      });
    },
  });

  const edit_data = useQuery(
    ["stock_data", searchParams.get("id")],
    () => stock_apis.get_stock_transfer_details(searchParams.get("id")),
    {
      refetchOnWindowFocus: false,
      enabled: searchParams.get("id") !== null,
      onSuccess: data => {
        fillEditData(data.data);
      },
    }
  );

  const fillEditData = data => {
    console.log("speaking from edit stock transfer", data);
    reset({
      from_counter: data.from_counter,
      to_counter: data.to_counter,
      date: moment(data.created_at, "DD-MM-YYYY"),
    });
    setProducts(
      data.stock_list.map(e => {
        return {
          // id: e.id,
          edit_id: e.id,
          title: e.product,
          stock_qty: e.available_product_stock_qty,
          batch: {
            value: e.batch,
            label: e.batch_no,
          },
          selling_price: e.selling_price,
          qty: e.stock,
        };
      })
    );
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setValues,
    getValues,
    register: form,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      from_counter: "",
      to_counter: "",
      date: new Date(),
    },
    resolver: joiResolver(validationSchema),
  });

  const handleAddProduct = product => {
    console.log(products, product);
    const product_already_exists = products.find(e => e.batch.value === product.batch.value);
    if (product_already_exists) {
      showErrorToast({ title: "Error", message: "A product with same batch already exists" });
    } else {
      setProducts(
        produce(draft => {
          draft.push({ ...product, qty: "" });
        })
      );
      setRender(e => !e);
    }
  };

  const addProduct = () => {
    const formValues = getValues();

    console.log("products", products);

    const products_data = products.map(e => {
      return {
        id: e.edit_id,
        product: e.id,
        batch: e.batch?.value || null,
        stock: e.qty,
      };
    });

    let payload;

    if (isEditing) {
      payload = {
        ...formValues,
        stock_list_id: +searchParams.get("id"),
        date: moment(formValues.date).format("DD-MM-YYYY"),
        stock_list_item: products_data.filter(value => value.stock),
        amount: products.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.selling_price * currentValue.qty;
        }, 0),
      };
    } else {
      payload = {
        ...formValues,
        date: moment(formValues.date).format("DD-MM-YYYY"),
        stock_list_item: products_data.filter(value => value.stock),
        amount: products.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.selling_price * currentValue.qty;
        }, 0),
      };
    }

    if (!products.length) {
      showErrorToast({
        title: "Oops!",
        message: "Please add one or more products to transfer stock.",
      });
    } else if (!payload.stock_list_item.length) {
      showErrorToast({
        title: "Oops!",
        message: "Invalid Quantity",
      });
    } else {
      console.log({ payload });
      transferStock.mutate(payload);
    }
  };

  const transferStock = useMutation({
    mutationFn: data =>
      isEditing ? stock_apis.edit_stock_transfer(data) : stock_apis.transfer_stock(data),
    onSuccess: data => {
      console.log(data);
      showSuccessToast({ title: "Success", message: data.data?.message });
      navigate("/stock-transfer");
    },
    onError: () => {
      showErrorToast({ title: "Error", message: "Error transferring stock." });
    },
  });

  const updateQty = (batch, qty) => {
    setProducts(
      produce(draft => {
        let product = draft.find(e => e.batch.value === batch);
        console.log("updating this product", product);
        product.qty = qty;
      })
    );
  };

  const handleKeyDown = event => {
    if (event.key === "F5") {
      event.preventDefault();
      addProduct();
    }

    if (event.key === "Enter") {
      event.preventDefault();
      setSelectProductModalOpened(true);
    }
  };

  const deleteProduct = id => {
    console.log(id);
    setProducts(products.filter((e, i) => i !== id));
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current?.value == "") {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
    console.log(products);
  }, [products]);

  useEffect(() => {
    if (searchParams.get("id")) {
      setIsEditing(true);
    }
  }, [searchParams]);

  return (
    <div>
      {isMobileScreen ? <></> : <AppPageHeader title="Transfer Stock" />}

      <Container fluid mt={10} mb={30}>
        <form
          onError={() => {
            console.log(errors);
          }}
          onSubmit={handleSubmit(values => {
            console.log(values);
            console.log("gey");

            addProduct();
          })}
        >
          <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
            <Box p={25} bg={"white"}>
              <Grid>
                <Col sm={4}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <DatePickerInput
                        valueFormat="DD-MM-YYYY"
                        label="Transfer Date"
                        placeholder="Select Date"
                        error={errors.date?.message}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Controller
                    control={control}
                    name="from_counter"
                    render={({ field }) => (
                      <Select
                        disabled={isEditing}
                        searchable
                        label="Transfer From"
                        data={counters}
                        placeholder="Search.."
                        error={errors.from_counter?.message}
                        {...field}
                      // value={wareHouseCounters[0]?.id}
                      />
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Controller
                    control={control}
                    name="to_counter"
                    render={({ field }) => (
                      <>
                        <Select
                          autoFocus={!location.pathname.includes("edit")}
                          searchable
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              setSelectProductModalOpened(true);
                            }
                          }}
                          disabled={isEditing}
                          label="Transfer To"
                          data={counters}
                          placeholder="Search.."
                          error={errors.to_counter?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Flex justify={"space-between"} align={"center"} mt={10}>
                    <Text fw={500}>Products List</Text>
                    <Button
                      leftIcon={<IconPlus />}
                      variant="outline"
                      onClick={() => {
                        setSelectProductModalOpened(true);
                      }}
                    >
                      Add Product
                    </Button>
                  </Flex>

                  <Box sx={{ overflow: "auto" }}>
                    <Table withBorder withColumnBorders mt={10}>
                      <thead>
                        <tr>
                          <th> Product Name </th>
                          <th> Batch </th>
                          <th> Available Qty </th>
                          <th> Transfer Qty </th>
                          <th> Selling Price </th>
                          <th> Total </th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((e, index) => {
                          if (e.stock_qty > 0) {
                            return (
                              <tr key={index}>
                                <td> {e.title} </td>
                                <td> {e.batch?.label} </td>
                                <td>
                                  <Text>{e.stock_qty}</Text>
                                </td>
                                <td>
                                  <NumberInput
                                    decimalSeparator="."
                                    precision={3} // Allows values like 0.500
                                    step={0.001} // Enables small decimal increments
                                    type="number"
                                    ref={inputRef}
                                    max={e.stock_qty}
                                    placeholder="Enter qty"
                                    w={200}
                                    min={0}
                                    value={e.qty}
                                    onChange={value => {
                                      if (value) {
                                        updateQty(e.batch.value, +value);
                                      }
                                    }}
                                    onKeyDown={handleKeyDown}
                                  />
                                </td>
                                <td>
                                  <Text>₹ {e.selling_price}</Text>
                                </td>
                                <td>
                                  <Text fw={500}>
                                    ₹ {(+e.selling_price * +e.qty).toFixed(2) || 0}{" "}
                                  </Text>
                                </td>
                                <td>
                                  <ActionIcon
                                    color="red"
                                    onClick={() => {
                                      deleteProduct(index);
                                    }}
                                  >
                                    <IconCircleMinus />
                                  </ActionIcon>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th> Total Amount </th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>
                            ₹&nbsp;
                            {products.reduce((previousValue, currentValue) => {
                              return previousValue + currentValue.selling_price * currentValue.qty;
                            }, 0).toFixed(2)}
                          </th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </Table>
                  </Box>

                  <Button mt={20} w={"100%"} type="submit">
                    Transfer selected stock (F5)
                  </Button>
                </Col>
              </Grid>
            </Box>
          </Box>
        </form>
      </Container>

      <SelectProductModal
        opened={selectProductModalOpened}
        onClose={() => {
          setSelectProductModalOpened(false);
        }}
        handleAddProduct={handleAddProduct}
      />
    </div>
  );
}

export default AddStockTransfer;
