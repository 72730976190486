import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Global styles
import "./styles/global.scss";
// React query
import { QueryClient, QueryClientProvider } from "react-query";

import { routes } from "./router/Routes";
import { theme_colors } from "config";
import { theme_font } from "config";
import { theme_font_sizes } from "config";
import { primary_shade } from "config";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  refetchOnReconnect: false,
  retry: false,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools /> */}
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        components: {
          Select: {
            styles: (theme) => ({
              item: {
                '&[data-hovered]': {
                  backgroundColor: '#c5edca', // Change hover color globally
                  fontWeight:'500',
                },
              },
            }),
          },
        },
        colors: {
          brand: theme_colors,
        },
        globalStyles: theme => ({
          body: {
            color: theme.colors.brand[8],
            background: "#e0e5eb33",
          },
        }),
        primaryShade: primary_shade,
        primaryColor: "brand",
        fontFamily: theme_font,
        fontSizes: JSON.parse(theme_font_sizes),
      }}
    >
      <ModalsProvider>
        <NotificationsProvider position="top-center">
          <BrowserRouter>{routes}</BrowserRouter>
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  </QueryClientProvider>
);
