import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import {
  IconBoxSeam,
  IconCash,
  IconCurrency,
  IconDownload,
  IconEdit,
  IconEye,
  IconFile,
  IconFile3d,
  IconPackage,
  IconPaperBag,
  IconPlus,
  IconPrinter,
  IconPrinterOff,
  IconPrison,
  IconTrash,
  IconTruckDelivery,
  IconWallet,
} from "@tabler/icons";
import { IconCashBanknote, IconCirclePlus } from "@tabler/icons-react";
import AddPaymentReceipt from "components/AddPaymentReceipt";
import FloatingMenu from "components/FloatingMenu";
import { InvoicePrintA4 } from "components/InvoicePrintA4";
import printTemplate from "components/InvoicePrintComponent";
import { PrintModal } from "components/PrintModal";
// import PaymentReceiptModal from "components/PaymentReceiptModal";
import ViewPaymentReceipts from "components/ViewPaymentReceipts";
import { MantineReactTable } from "mantine-react-table";
import moment from "moment";
import { getCounterId, hasPermission, isCounterAdmin } from "pages/Auth/permissionGuard";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";

import { invoice_api } from "./invoice.service";
import AppBreadcrumb from "../../../components/AppBreadcrumb";
import { api_addUser, api_filter } from "../../../services/master.service";
import { showErrorToast, showSuccessToast } from "../../../utilities/Toast";
import { api_addPaymentReceipt } from "../payment-receipt/paymentReceipt.service";

export default function InvoiceList() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery("(max-width: 768px)");

  // START : States
  const [barcodeData, setBarcodeData] = useState("Not Found");
  const [modalOpenedBarcode, setModalOpenedBarcode] = useState(false);
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const [invoiceA4, setInvoiceA4] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [addProductModalOpened, setAddProductModalOpened] = useState(false);
  // END : States

  // STARTS : States
  const [userFormVisible, setUserFormVisible] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [paymentReceiptFormVisible, setPaymentReceiptFormVisible] = useState(false);
  const [invoicesData, setInvoicesData] = useState([]);
  const [viewPaymentReceiptModalOpened, setViewPaymentReceiptModalOpened] = useState(false);
  const [viewPaymentReceiptId, setViewPaymentReceiptId] = useState({});

  // Refactoring payment receipts
  const [invoiceId, setInvoiceId] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [addPaymentReceiptOpened, setaddPaymentReceiptOpened] = useState(false);
  //

  const [countersList, setCountersList] = useState([]);
  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId() : "");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [counterData, setCounterData] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
      setCounterData(data.data);
    },
  });


  const invoices = useQuery({
    queryKey: ["orders-data", pagination.pageIndex, pagination.pageSize, counter, columnFilters],
    queryFn: async () => {
      if (columnFilters.length > 0) {
        return printFilter(false); // Call printFilter without setting CSV
      } else {
        return invoice_api.get_invoices(pagination.pageIndex + 1, pagination.pageSize, counter);
      }
    },
    keepPreviousData: true,
    onSuccess: data => {
      // console.log('qqq-24',data?.data)
      setData(data.data);
    },
  });


  const openDeleteConfirmation = id => {
    openConfirmModal({
      title: "Please confirm delete invoice",
      children: (
        <Text size="sm">
          Are you sure you want to delete this invoice ? Everything related to this invoice will be
          deleted.
        </Text>
      ),
      labels: { confirm: "Delete Invoice", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await deleteInvoice(id),
      confirmProps: { color: "red" },
    });
  };

  const deleteInvoice = async id => {
    await invoice_api.delete_invoice(id).then(res => {
      console.log(res.data);
    });
    invoices.refetch();
  };

  const columns = useMemo(
    () => [
      {
        header: "Invoice No.",
        accessorKey: "invoice_id",
        size: 50,
      },
      {
        accessorFn: originalRow => new Date(originalRow.create_at),
        id: "create_at",
        header: "Date",
        accessorKey: "create_at",
        size: 50,
        Cell: ({ cell }) => cell.getValue()?.toLocaleString("en-IN"),
        filterVariant: "date-range",
      },
      {
        header: "Name",
        accessorKey: "user",
        size: 250,
      },
      {
        header: "Amount",
        accessorKey: "total",
        filterVariant: "range",

        size: 50,
        Cell: ({ cell }) => (
          <span className="ls-1">₹ {cell.getValue().toFixed(2)?.toLocaleString("en-IN")}</span>
        ),
      },
    ],
    []
  );

  const openDeliveryConfirmation = id => {
    openConfirmModal({
      title: "Please confirm deliver invoice",
      children: <Text size="sm">This action cannot be undone.</Text>,
      labels: { confirm: "Deliver Invoice", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => await deliverInvoice(id),
      confirmProps: { color: "primary" },
    });
  };

  const deliverInvoice = async id => {
    await invoice_api.deliver_invoice(id).then(res => {
      console.log(res.data);
      if (res.data.success) {
        showSuccessToast({
          title: "Success",
          message: res.data.message,
        });
        invoices.refetch();
      } else {
        showErrorToast({
          title: "Error",
          message: res.data.message,
        });
      }
    });
  };

  const printInvoice = async id => {
    await invoice_api.get_invoice_print_data_by_id(id).then(res => {
      const data = res.data.data;

      const item = data.invoice_item.map(e => {
        return `<div style="white-space: nowrap;">
              <p  style="display: inline-block;width: 55%;padding:0px;">${e.product}</p> 
              <p  style="display: inline-block;width: 10%;text-align:right;padding:0px;">${e.mrp.toFixed(
          2
        )} &emsp; </p> 
              <p  style="display: inline-block;text-align:center;width: 35%;padding:0px;">${e.batch_no
          }</p> 
             <br/> 
              <p  style="display: inline-block;width: 10%;text-align:right;padding:0px;">${e.qty
          }</p> 
              <p  style="display: inline-block;width: 30%;text-align:right;padding:0px;">${e.rate.toFixed(
            2
          )}</p>
              <p  style="display: inline-block;width: 10%;text-align:right;padding:0px;">${e.tax
          }%</p> 
              <p  style="display: inline-block;width: 10%;text-align:right;padding:0px;">${e.discount
          }%</p> 
              <p  style="display: inline-block;width: 30%;text-align:right;padding:0px;">${e.amount.toFixed(
            2
          )}</p> 
          </div>`;
      });
      const qty = data.invoice_item.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.qty;
      }, 0);
      const items = item.join("");
      const printWindow = window.open("", "", "width=600,height=600");
      printWindow.document.open();
      printWindow.document.write(printTemplate(data, items, moment, qty));
      printWindow.document.close();

      printWindow.print();
      printWindow.close();
    });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [3];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const a4Ref = useRef();
  const handlePrintA4 = useReactToPrint({
    content: () => a4Ref.current,
  });

  useEffect(() => {
    if (invoiceA4 !== null) {
      handlePrintA4();
    }
  }, [invoiceA4]);

  const printInvoiceA4 = async id => {
    await invoice_api.get_invoice_print_data_by_id(id).then(res => {
      const data = res.data.data;
      setInvoiceA4(data);
    });
  };

  // const printFilter = () => {
  //   const model = "Invoice";

  //   const appendData = [
  //     { id: "invoice_id", append: "" },
  //     { id: "create_at", append: "" },
  //     { id: "first_name", append: "user__", replace_id: "user" },
  //     { id: "total", append: "" },
  //   ];
  //   const custom = getCustomId(columns, columnFilters, appendData);
  //   const filter = getFilterPayload(custom?.columns, custom?.columnFilters);
  //   const fields = getFields(custom?.columns);

  //   api_filter(model, filter, fields)
  //     .then(res => {
  //       if (res.success) {
  //         console.log('qqq-23',res.data);
  //         const head_content = getHeaders(columns);
  //         const body_content = getBodyContent(res.data, fields);
  //         const csv_content = getCsvContent(head_content, body_content, ["INVOICE"]);
  //         const columnTotalIndex = [3];
  //         const foot_content = getTotal(body_content, columnTotalIndex);
  //         setFilename(
  //           "INVOICE" +
  //             "_" +
  //             countersList?.find(e => e.id === counter)?.label +
  //             "_" +
  //             new Date().toLocaleDateString() +
  //             ".csv"
  //         );
  //         setCsv(csv_content);
  //         setHead(head_content);
  //         setBody(body_content);
  //         setFoot(foot_content);
  //       } else {
  //         showErrorToast({ title: "Error", message: res.message });
  //       }
  //     })
  //     .catch(e => {
  //       showErrorToast({ title: "Error", message: e.message });
  //     });
  // };

  const printFilter = async (forCsv = true) => {
    const model = "Invoice";
    const appendData = [
      { id: "invoice_id", append: "" },
      { id: "create_at", append: "" },
      { id: "first_name", append: "user__", replace_id: "user" },
      { id: "total", append: "" },
    ];
    const custom = getCustomId(columns, columnFilters, appendData);
    const filterPayload = getFilterPayload(custom?.columns, custom?.columnFilters);
    const fields = getFields(custom?.columns);

    try {
      const res = await api_filter(model, filterPayload, fields, "InvoiceSerializer");
      if (res.success) {
        if (forCsv) {
          // CSV Export
          const head_content = getHeaders(columns);
          const body_content = getBodyContent(res.data, fields);
          const csv_content = getCsvContent(head_content, body_content, ["INVOICE"]);
          const columnTotalIndex = [3];
          const foot_content = getTotal(body_content, columnTotalIndex);
          setFilename(
            "INVOICE" +
            "_" +
            countersList?.find(e => e.id === counter)?.label +
            "_" +
            new Date().toLocaleDateString() +
            ".csv"
          );
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          // Table Filtering
          return { data: res.data };
        }
      } else {
        showErrorToast({ title: "Error", message: res.message });
        return { data: [] };
      }
    } catch (e) {
      showErrorToast({ title: "Error", message: e.message });
      return { data: [] };
    }
  };

  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      <div style={{ display: "none" }}>
        <InvoicePrintA4 data={invoiceA4} counter={counterData} ref={a4Ref} />
      </div>
      {isMobileScreen ? (
        <>
          <Select
            p={5}
            label="Counter"
            defaultValue={counter || null}
            placeholder="Select Counter"
            data={countersList || []}
            onChange={setCounter}
            clearable
            disabled={isCounterAdmin()}
          />
        </>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Invoices
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Transactions",
                    },
                    {
                      text: "Invoices",
                    },
                  ]}
                />
              </Box>
              <Box>
                <Flex align={"end"} gap={10}>
                  {/* <Button onClick={() => { setModalOpenedBarcode(true) }}>Scan</Button> */}
                  <Button
                    onClick={() => {
                      setIsCsv(true);
                      printFilter();
                    }}
                    leftIcon={<IconDownload />}
                  >
                    CSV
                  </Button>
                  {/* <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      print(true);
                    }}
                  >
                    Print
                  </Button> */}
                  <Button
                    leftIcon={<IconPrinter />}
                    onClick={() => {
                      printFilter();
                    }}
                  >
                    Print
                  </Button>
                  {hasPermission("add_invoice") && (
                    <Button
                      onClick={() => {
                        navigate("add");
                      }}
                    >
                      <IconPlus style={{ width: "20px" }} />
                      <Text ml={10}> Create New Invoice </Text>
                    </Button>
                  )}
                  <Select
                    label="Counter"
                    defaultValue={counter || null}
                    placeholder="Select Counter"
                    data={countersList || []}
                    onChange={setCounter}
                    clearable
                    disabled={isCounterAdmin()}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}
        <Box px={isMobileScreen ? 5 : 24} py={10}>
          <Box className="datatable-wrapper" ref={componentRef}>
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={{ columnFilters, pagination }} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable" }}
              enableRowActions
              positionActionsColumn="last"
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    Invoices List
                  </Text>
                </Box>
              )}
              renderRowActions={({ row }) => {
                console.log('qqq-009', row)
                return (
                  <Flex>
                    <Tooltip label="Mark as delivered" disabled={row.original.delivered}>
                      <ActionIcon
                        disabled={row.original.delivered}
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        onClick={() => {
                          openDeliveryConfirmation(row.original.id);
                          // navigate(`print/?id=${row.original.id}`);
                        }}
                      >
                        <IconTruckDelivery style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                    {row.original?.order !== null ? (
                      <Tooltip label="View Order">
                        <ActionIcon
                          ml={10}
                          sx={theme => ({ color: theme.colors.brand[7] })}
                          onClick={() => {
                            navigate(`/orders/view?id=${row.original?.order}`);
                          }}
                        >
                          <IconBoxSeam style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                    <Tooltip label="Print Invoice">
                      <ActionIcon
                        ml={10}
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        onClick={() => {
                          // navigate(`print/?id=${row.original.id}`);
                          // const pdfUrl = `https://dev.quzone.in/api/print_invoice/?invoice_id=${row.original.id}`;
                          // window.open(pdfUrl, '_blank');
                          printInvoice(row.original.id);
                        }}
                      >
                        <IconPrinter style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Print Invoice A4">
                      <ActionIcon
                        ml={10}
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        onClick={() => {
                          printInvoiceA4(row.original.id);
                        }}
                      >
                        <IconFile style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="View Payment Receipt">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          setInvoiceId(row.original.id);
                          setSelectedInvoice(row.original);
                          setViewPaymentReceiptId(row.original);
                          setViewPaymentReceiptModalOpened(true);
                        }}
                      >
                        <IconCash style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Add Payment Receipt">
                      <ActionIcon
                        disabled={Number(row?.original?.balance_amount) <= 0 ? true : false}
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          setInvoiceId(row.original.id);
                          setSelectedInvoice(row.original);
                          setaddPaymentReceiptOpened(true);
                        }}
                      >
                        <IconCirclePlus style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>

                    {hasPermission("change_invoice") ? (
                      <Tooltip label="Edit Invoice">
                        <ActionIcon
                          disabled={row.original.delivered}
                          sx={theme => ({ color: theme.colors.brand[7] })}
                          ml={10}
                          onClick={() => {
                            navigate(`edit?id=${row.original.id}`);
                          }}
                        >
                          <IconEdit style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    <Tooltip label="View Invoice">
                      <ActionIcon
                        sx={theme => ({ color: theme.colors.brand[7] })}
                        ml={10}
                        onClick={() => {
                          navigate(`view?id=${row.original.id}`);
                        }}
                      >
                        <IconEye style={{ width: 20 }} />
                      </ActionIcon>
                    </Tooltip>

                    {hasPermission("delete_invoice") && (
                      <Tooltip label="Delete Invoice">
                        <ActionIcon
                          disabled={row.original.delivered}
                          sx={theme => ({ color: theme.colors.red[6] })}
                          ml={10}
                          onClick={() => {
                            openDeleteConfirmation(row.original.id);
                          }}
                        >
                          <IconTrash style={{ width: 20 }} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </Flex>
                )
              }
              }
              initialState={{ density: "xs" }}
              // state={{ isLoading: users_data.isLoading }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              data={invoices.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={false}
              //state={{
              //  isLoading: invoices.isLoading || invoices.isRefetching,
              //  pagination,
              //}}
              // onPaginationChange={setPagination}
              onPaginationChange={(updater) => {
                setPagination((prev) => {
                  const newPagination = typeof updater === "function" ? updater(prev) : updater;
                  return { ...prev, ...newPagination };
                });
              }}
              rowCount={invoices.data?.count}
              manualPagination
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}

      <Modal
        opened={modalOpenedBarcode}
        onClose={() => {
          setModalOpenedBarcode(false);
        }}
        title={
          <Box px={10}>
            <Text fw={600}>Scan Barcode</Text>
          </Box>
        }
        centered
      >
        <Box px={10}>
          <Divider mb={20} />

          <BarcodeScannerComponent
            width={500}
            height={500}
            onUpdate={(err, result) => {
              if (result) setBarcodeData(result.text);
              else setBarcodeData("Not Found");
            }}
          />
          <Text>{barcodeData}</Text>
        </Box>
      </Modal>

      {viewPaymentReceiptModalOpened && (
        <ViewPaymentReceipts
          opened={viewPaymentReceiptModalOpened}
          onClose={() => {
            setViewPaymentReceiptModalOpened(false);
          }}
          invoiceId={invoiceId}
          invoiceData={selectedInvoice}
        // viewPaymentReceiptId={viewPaymentReceiptId}
        // viewPaymentReceiptModalOpened={viewPaymentReceiptModalOpened}
        // setViewPaymentReceiptModalOpened={setViewPaymentReceiptModalOpened}
        />
      )}

      {addPaymentReceiptOpened && (
        <AddPaymentReceipt
          opened={addPaymentReceiptOpened}
          onClose={() => {
            setaddPaymentReceiptOpened(false);
            setTimeout(() => {
              invoices.refetch()
            }, 500);
          }}
          invoiceId={invoiceId}
          defaultData={selectedInvoice}
        />
      )}
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[3]}
          title="Invoices"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {hasPermission("add_invoice") && isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            ml={60}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              navigate("add");
            }}
          >
            <IconPlus color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
