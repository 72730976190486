import { Flex, Image, Text } from "@mantine/core";
import Barcode from "react-barcode";
import { renderIntoDocument } from "react-dom/test-utils";
import QRCode from "react-qr-code";

import qr_code from "../static/images/qr_code.png";

function printTemplate(data, items, moment, qty) {
  const invoice_date = moment(data.create_at);
  const return_date = moment(data.create_at);

  const distinctValues = [...new Set(data.invoice_item.map(item => item.tax))];

  console.log(data);

  const barcode = renderIntoDocument(
    <div style={{ width: "100%" }}>
      <Text>
        You can exchange this item within this date{" "}
        {return_date.add(3, "days").format("DD-MM-YYYY HH:MM")}{" "}
      </Text>
      <Barcode
        value={data?.invoice_id !== null ? data.invoice_id : ""}
        format="CODE39"
        height={50}
      />
      <table style={{ width: "100%" }}>
        <tr>
          {data?.no_of_box > 0 && <th>No. of Box</th>}
          {data?.no_of_bags > 0 && <th>No. of Bags</th>}
          {data?.no_of_rice_bags > 0 && <th>No. of Rice Bags</th>}
        </tr>
        <tr>
          {data?.no_of_box > 0 && <td>{data?.no_of_box}</td>}
          {data?.no_of_bags > 0 && <td>{data?.no_of_bags}</td>}
          {data?.no_of_rice_bags > 0 && <td>{data?.no_of_rice_bags}</td>}
        </tr>
      </table>
      <Text>Pay Using</Text>

      <QRCode size={"120px"} value={"https://www.quzone.in/"} />
      {/* <img
        src={qr_code}
        style={{
          objectFit: "cover",
        }}
        alt="qrcode"
      /> */}
    </div>
  );

  const taxDetails = distinctValues.map(value => [
    value,
    data?.invoice_item
      ?.filter(item => item.tax === value)
      .reduce((acc, cur) => acc + (cur.amount - cur.tax_amount), 0)
      .toFixed(2),
    data?.invoice_item
      ?.filter(item => item.tax === value)
      .reduce((acc, cur) => acc + cur.tax_amount, 0)
      .toFixed(2),
  ]);
  console.log(taxDetails);

  const delivery_tax = data?.delivery_taxable * (data?.delivery_tax / 100);
  const total_tax = data?.tax_total + delivery_tax;

  // const sameTax = taxDetails.find(v => v.value === data?.delivery_tax);
  // if (sameTax !== undefined) {
  //   taxDetails[taxDetails.findIndex(v => v.value === data?.delivery_tax)] = {
  //     value: data?.delivery_tax,
  //     tax_amount: (parseFloat(data?.delivery_taxable) + parseFloat(sameTax.tax_amount))?.toFixed(2),
  //     taxable_amount: (parseFloat(delivery_tax) + parseFloat(sameTax.taxable_amount))?.toFixed(2),
  //   };
  // } else {
  //   taxDetails.push({
  //     value: data?.delivery_tax,
  //     tax_amount: data?.delivery_taxable,
  //     taxable_amount: delivery_tax,
  //   });
  // }

  const sameTax = taxDetails.find(v => v[0] === data?.delivery_tax);
  if (sameTax !== undefined) {
    taxDetails[taxDetails.findIndex(v => v[0] === data?.delivery_tax)] = [
      data?.delivery_tax,
      (parseFloat(data?.delivery_taxable) + parseFloat(sameTax[1]))?.toFixed(2),
      (parseFloat(delivery_tax) + parseFloat(sameTax[2]))?.toFixed(2),
    ];
  } else if (data?.delivery_tax !== 0 && data?.delivery_taxable !== 0 && delivery_tax !== 0) {
    taxDetails.push([data?.delivery_tax, data?.delivery_taxable, delivery_tax]);
  }

  const tax_item = taxDetails.map(e => {
    return `<div style="display: flex;justify-content: space-evenly;">
                    <p style="width: 15%;">${e[0]}</p>
                    <p style="width: 35%;text-align:right;">${parseFloat(e[1])?.toFixed(2)} </p>
                    <p style="width: 35%;text-align:right;">${parseFloat(e[2])?.toFixed(2)}</p>
                </div>`;
  });
  const tax_items = tax_item.join("");

  return `<!DOCTYPE html>
            <html lang="en">

            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Quzone</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">
                <style>
                    @media print {
                        body {
                            width: 104mm !important;
                            height: 3276mm !important;
                            margin: 0.2mm 0.2mm 0.2mm 0.2mm !important;
                            /* change the margins as you want them to be. */
                        }
                    }
                    @page {
                    width: 104mm !important;
                    height: 3276mm !important;
                    margin: 0.2mm 0.2mm 0.2mm 0.2mm !important;
                    /* change the margins as you want them to be. */
                }
                p{
                    margin: 0px !important;
                }
                </style>
            </head>

            <body style="font-family:  Calibri, sans-serif;">
                <div style="text-align: center;">
                    <h1>QUZONE</h1>
                    <!-- <p>${data.counter_name}</p><br> -->
                    <p>${data.counter_address}</p>
                    <p>${data.counter_person}</p>

                </div>
                <div style="text-align: center;">
                    <p>FASSAI license no:${data.counter_fssai}</p><br>
                    <div style="display: flex;justify-content: space-evenly;">
                        <div>
                            <p>GST:${data.counter_gst}</p>
                        </div>
                        <div>
                            <p>PH No:+91 ${data.counter_number}</p>
                        </div>
                    </div>
                </div>
                <div style="font-size:20px;text-align:center;">
                    <p>Tax Invoice</p>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="display: flex;justify-content: space-evenly;">
                    <div>
                        <p>Bill no: </b>${data.invoice_id}</p>
                    </div>
                    <div>
                        <p>Data & Time :${invoice_date.format("DD-MM-YYYY HH:MM")}</p>
                    </div>
                </div>
                <div style="font-size: 20px;">
                    <b>Name:${data.name}</b> <br>
                    <b>Phone No:${data.number}</b>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="display: flex;justify-content: space-evenly;">
                <b style="width: 55%;">Item<br/>Qty Rate Tax</b>
                <b style="width: 22.5%;">Mrp<br/>Disc</b>
                <b style="width: 22.5%;">Batch<br/>Amount</b>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                ${items}
                
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div>
                    <div style="display: flex;">
                        <div style="width:40%;">Sub Total</div>
                        <div style="text-align:right;width: 55%;">${+data.sub_total.toFixed(
                          2
                        )}</div>
                    </div>
                    <div style="display: flex;">
                        <div style="width:40%;">Delviery Charges</div>
                        <div style="text-align:right;width:55%;">${data?.delivery_taxable?.toFixed(
                          2
                        )}</div>
                    </div>
                    <div style="display: flex;">
                        <div style="width:40%;">Packing Charges</div>
                        <div style="text-align:right;width:55%;">${data.packing_charge.toFixed(
                          2
                        )}</div>
                    </div>
                    <div style="display: flex;">
                        <div style="width:40%;">Tax Total</div>
                        <div style="text-align:right;width:55%;">${total_tax.toFixed(2)}</div>
                    </div>
                    <div style="display: flex;">
                        <div style="width:40%;">Discount Amount</div>
                        <div style="text-align:right;width:55%;">${data.discount_total.toFixed(
                          2
                        )}</div>
                    </div>
                    <!-- <div style="display: flex;">
                        <div style="width:40%;">Net Amount</div>
                        <div style="text-align:right;width:55%;">${(data?.total).toFixed(2)}</div>
                    </div> -->
                    <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                    <div style="display: flex;justify-content: space-evenly;">
                        <div>Total</div>
                        <div>${data.invoice_item.length} Items</div>
                        <div>${qty} qty</div>
                        <div>Net Amount</div>
                        <div>${(data?.total).toFixed(2)}</div>
                    </div>
                </div> 
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="text-align: center;">
                    <ion-label>Tax Details</ion-label>
                </div>
                <div style="display: flex;justify-content: space-evenly;">
                    <b style="width: 40%;">CGST+SGST</b>
                    <b style="width: 30%;">Taxable </b>
                    <b style="width: 30%;text-align:center;">Tax </b>
                </div>
                ${tax_items}
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="text-align: left;">
                    <ion-label>&nbsp;Cashier : ${data.invoiced_user} </ion-label><br>
                    <ion-label>&nbsp;Last day for exchange is : ${invoice_date
                      .add(3, "days")
                      .format("DD-MM-YYYY HH:MM")} </ion-label><br>
                </div>
                <hr style="height:2px; width:100%; border-width:0; color:black; background-color:black">
                <div style="text-align: center;">
                ${barcode.innerHTML}
                </div>
                <div style="text-align: center;">
                    <ion-label>Thanks For Purchase With QUZONE </ion-label><br>
                    <ion-label>* Visit Again * </ion-label>
                </div>

            </body>

            </html>`;
}

export default printTemplate;
