import { httpClient } from "../lib/axios";

export const api_getUsers = async () => {
  return await httpClient
    .get("app_user/")
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

export const api_addUser = async payload => {
  return await httpClient
    .post("app_user/", payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const api_getRoles = async () => {
  return await httpClient
    .post("crud/", {
      function_type: "read_all",
      data: null,
      many: true,
      app_name: "com",
      model_name: "roles",
    })
    .then(res => {
      let modified_data = res.data?.data.map(e => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      return modified_data;
    })
    .catch(err => {
      return err;
    });
};

export const api_getRoute = async () => {
  return await httpClient
    .post("crud/", {
      function_type: "read_all",
      data: null,
      many: true,
      app_name: "com",
      model_name: "route",
    })
    .then(res => {
      let modified_data = res.data?.data.map(e => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      return modified_data;
    })
    .catch(err => {
      return err;
    });
};

// Filter master
export const api_filter = async (model, filter, fields,serializer_class) => {
  return await httpClient
    .post("filter/", {
      model: model,
      filter: filter,
      fields: fields,
      serializer_class:serializer_class
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
