import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Flex,
  Modal,
  Select,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconDownload, IconEye, IconPrinter } from "@tabler/icons";
import FloatingMenu from "components/FloatingMenu";
import { PrintModal } from "components/PrintModal";
import { MantineReactTable } from "mantine-react-table";
import { getCounterId, isCounterAdmin } from "pages/Auth/permissionGuard";
import { counters_api } from "pages/master/counter/counter.service";
import { getAlteredCounters } from "pages/master/customers/helperFunctions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useQuery } from "react-query";
import { useReactToPrint } from "react-to-print";
import {
  clearCustomId,
  getBodyContent,
  getCsvContent,
  getCustomId,
  getFields,
  getFilterPayload,
  getHeaders,
  getTotal,
} from "services/filterHelperFunctions";
import { api_filter } from "services/master.service";
import { showErrorToast } from "utilities/Toast";

import { stock_apis } from "./stock-transfer/stock-transfer.service";
import AppBreadcrumb from "../../components/AppBreadcrumb";
import { api_getProducts } from "../../services/product.service";
import { order_api } from "pages/OrderManagement/order.service";
import moment from "moment";


export default function StockList() {
  const [head, setHead] = useState([]);
  const [body, setBody] = useState([]);
  const [foot, setFoot] = useState([]);
  const [csv, setCsv] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const [countersList, setCountersList] = useState([]);
  const [counter, setCounter] = useState(isCounterAdmin() ? getCounterId() : "");
  let isMobileScreen = useMediaQuery("(max-width: 768px)");
  const [columnFilters, setColumnFilters] = useState([]);
  const [filename, setFilename] = useState("file");
  const [modalBatches, setModalBatches] = useState({ title: '', batch: [] })
  const [productSelectionModal, setProductSelectionModal] = useState(false)
  const [apiModel, setApiModel] = useState("Products")

  const counter_data = useQuery({
    queryKey: ["counters-data-list"],
    queryFn: () => counters_api.get(),
    onSuccess: data => {
      setCountersList(getAlteredCounters(data.data));
    },
  });

  const stock_data = useQuery({
    queryKey: ["stock-list-data", counter, columnFilters], // Include filters in queryKey
    queryFn: async () => {
      if (columnFilters.length > 0) {
        return printFilter(false); // Fetch filtered data when table filters are applied
      } else {
        return stock_apis.get_stock_list_for_counter_view(counter);
      }
    },
    keepPreviousData: true,
  });


  const columns = useMemo(
    () => [
      {
        header: "Product Code",
        accessorKey: "product_code",
        size: "auto",
        filterVariant: "range",
      },
      {
        header: "Product Name",
        accessorKey: "title",
        size: "auto",
      },
      {
        header: "MRP",
        accessorKey: "mrp",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => <span> ₹ {cell.getValue()}</span>,
      },
      {
        header: "Price",
        accessorKey: "selling_price",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => <span> ₹ {cell.getValue()}</span>,
      },
      {
        header: "Stock Qty.",
        accessorKey: "stock_qty",
        size: "auto",
        filterVariant: "range",
        Cell: ({ cell }) => <Text fw={"500"}> {cell.getValue()}</Text>,
      },
      {
        header: "Total",
        size: "auto",
        Cell: ({ cell }) => {
          return (
            <>
              <Text fw={"500"}>
                {" "}
                {(cell.row.original.selling_price * cell.row.original.stock_qty).toFixed(2)}
              </Text>
            </>
          );
        },
      },
      {
        header: "Acpt Ord Qty",
        size: "auto",
        Cell: ({ cell }) => <span>{cell.row.original?.ordered_qty}</span>,
      },
      {
        header: "BB Stock",
        size: "auto",
        Cell: ({ cell }) => <Text fw={"500"}> {cell.row.original.bb_stock ? cell.row.original.bb_stock : '-'}</Text>,
      },
      {
        header: "BB Day",
        size: "auto",
        Cell: ({ cell }) => <Text fw={"500"}> {cell.row.original.bb_date ? getExpiryDayLeft(cell.row.original.bb_date) : '-'}</Text>,
      },
      {
        header: "BB",
        size: "auto",
        Cell: ({ cell }) => {
          const model = apiModel == "Products" ? "batch" : "counter_batch";
          return (
            <div>
              <Tooltip label="View Best Before">
                <ActionIcon
                  sx={theme => ({ color: theme.colors.brand[7] })}
                  onClick={() => {
                    setProductSelectionModal(true)
                    setModalBatches({ title: cell?.row?.original?.title, batch: cell?.row?.original?.[model] })
                  }}
                >
                  <IconEye style={{ width: 20 }} />
                </ActionIcon>
              </Tooltip>
            </div>
          )
        }
      },
    ],
    []
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    //console.log(head);
    if (body.length !== 0 && head.length !== 0) {
      if (!isCsv) {
        handlePrint();
        setBody([]);
        setHead([]);
      }
    }
    setIsCsv(false);
  }, [body, head]);

  // const get_text = html => {
  //   let div = document.createElement("div");
  //   div.innerHTML = html;
  //   let text = div.textContent || div.innerText || "";
  //   return text;
  //   //return parseFloat(text.replace(/[^0-9.]/g, ""));
  // };

  // const get_sum = removeAction => {
  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   let column_total = [];
  //   datatablebody?.forEach(e => {
  //     let row_total = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row_total.push(parseFloat(get_text(tag?.innerHTML).replace(/[^0-9.]/g, "")));
  //       }
  //     });
  //     column_total.push(row_total);
  //   });

  //   let total = [];
  //   column_total.map((e, i) => {
  //     e.map((h, index) => {
  //       if (i === 0) {
  //         total.push(h);
  //       } else {
  //         total[index] = h + total[index];
  //       }
  //     });
  //   });

  //   let nan_count = 0;
  //   total.map((e, i) => {
  //     if (isNaN(e)) {
  //       nan_count += 1;
  //     }
  //   });
  //   if (nan_count === total.length) {
  //     setFoot([]);
  //   } else {
  //     setFoot(total);
  //   }

  //   return total;
  // };

  // const print = removeAction => {
  //   var head_content = [];
  //   var body_content = [];
  //   const datatable = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("thead")
  //     ?.querySelectorAll("th");
  //   datatable !== undefined &&
  //     datatable?.forEach((e, i) => {
  //       if (!removeAction || datatable.length !== i + 1) {
  //         head_content.push(e?.querySelector(".mantine-TableHeadCell-Content-Wrapper")?.innerText);
  //       }
  //     });

  //   const datatablebody = document
  //     .querySelector(".datatable-wrapper")
  //     ?.querySelector("tbody")
  //     ?.querySelectorAll("tr");
  //   datatablebody?.forEach(e => {
  //     let row = [];
  //     const d = e?.querySelectorAll("td");
  //     d?.forEach((tag, i) => {
  //       if (!removeAction || d.length !== i + 1) {
  //         row.push(get_text(tag?.innerHTML));
  //       }
  //     });
  //     body_content.push(row);
  //   });

  //   setHead(head_content);
  //   setBody(body_content);
  //   let csv_data = [];
  //   csv_data.push(head_content);
  //   body_content.map((e, i) => {
  //     csv_data.push(e);
  //   });
  //   const cols = [2, 3, 4, 5];
  //   let sum = [];
  //   get_sum(removeAction).map((e, i) => {
  //     if (isNaN(e) || !cols?.includes(i, 0)) {
  //       sum.push("");
  //     } else {
  //       sum.push(e);
  //     }
  //   });
  //   csv_data.push(sum);
  //   setCsv(csv_data);
  // };

  const getExpiryDayLeft = expiry_date => {
    const expirationDate = new Date(expiry_date);
    const currentDate = new Date();
    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  };

  const printFilter = async (forCsv = true) => {
    const model = apiModel == "Products" ? "Products" : "CounterStock";

    const appendData = {
      "Products": [
        { id: "product_code", append: "", replace_id: "product_code" },
        { id: "title", append: "", replace_id: "title" },
        { id: "mrp", append: "", replace_id: "mrp" },
        { id: "selling_price", append: "", replace_id: "selling_price" },
        { id: "stock_qty", append: "", replace_id: "stock_qty" },
        { id: "ordered_qty", append: "", replace_id: "ordered_qty" },
      ],
      "CounterStock": [
        { id: "product_code", append: "product__", replace_id: "product_code" },
        { id: "title", append: "product__", replace_id: "title" },
        { id: "mrp", append: "product__", replace_id: "mrp" },
        { id: "selling_price", append: "product__", replace_id: "selling_price" },
        { id: "stock_qty", append: "product__", replace_id: "stock_qty" },
        { id: "ordered_qty", append: "product__", replace_id: "ordered_qty" },
      ],
    }

    const serializer = apiModel == "Products" ? "ProductStockSerializer" : "CounterStockSerializer"

    const custom = getCustomId(columns, columnFilters, appendData?.[apiModel]);
    const filterPayload = getFilterPayload(custom?.columns, custom?.columnFilters);
    // const fields = getFields(custom?.columns);

    const fields=["product_code","title","mrp","selling_price","stock_qty","ordered_qty","bb_stock","bb_date"]

    try {
      const res = await api_filter(model, filterPayload, fields, serializer);
      if (res.success) {
        console.log('qqq-1111',res)
        let body_content = getBodyContent(res.data, fields);

        // Calculate additional column value (selling_price * stock_qty)
        body_content = body_content.map(e => {
          e.push(((e[3] ?? 0) * (e[4] ?? 0)).toFixed(2)); // Existing logic for index 5
          e[7] = e[7] ? getExpiryDayLeft(e[7]) : ""; // Format index 7
          return e;
        });
        
        if (forCsv) {
          // **CSV Export**
          const head_content = [...getHeaders(columns).filter((value)=>!["Total","BB"].includes(value)),"Total"];
          const csv_content = getCsvContent(head_content, body_content, ["STOCKS"]);
          const columnTotalIndex = [2, 3, 4, 5]; // Totals for selected columns
          const foot_content = getTotal(body_content, columnTotalIndex);

          setFilename(`STOCKS_${countersList?.find(e => e.id === counter)?.label}_${new Date().toLocaleDateString()}.csv`);
          setCsv(csv_content);
          setHead(head_content);
          setBody(body_content);
          setFoot(foot_content);
        } else {
          // **Table Filtering**
          return { data: res.data };
        }
      } else {
        showErrorToast({ title: "Error", message: res.message });
        return { data: [] };
      }
    } catch (e) {
      showErrorToast({ title: "Error", message: e.message });
      return { data: [] };
    }
  };


  const csvRef = useRef();
  useEffect(() => {
    if (csv.length && isCsv) {
      csvRef.current.link.click();
      setCsv([]);
      setIsCsv(false);
    }
  }, [csv]);

  useEffect(()=>{
    if(counter){
      setApiModel("CounterStock")
    }else{
      setApiModel("Products")
    }
  },[counter])

  return (
    <>
      <CSVLink
        style={{ display: "none" }}
        asyncOnClick={true}
        data={csv}
        filename={filename}
        ref={csvRef}
      ></CSVLink>
      {isMobileScreen ? (
        <>
          <Select
            p={5}
            label="Counter"
            defaultValue={isCounterAdmin() ? counter : null}
            placeholder="Select Counter"
            data={countersList || []}
            onChange={setCounter}
            clearable
            disabled={isCounterAdmin()}
          />
        </>
      ) : (
        <Container fluid>
          <Box p={25}>
            <Flex justify={"space-between"} align="end">
              <Box>
                <Text size={"lg"} fw={600} mb={10}>
                  Stock List
                </Text>
                <AppBreadcrumb
                  crumbs={[
                    {
                      text: "Inventory",
                    },
                    {
                      text: "Stock List",
                    },
                  ]}
                />
              </Box>

              <Flex align={"flex-end"} gap={10}>
                <Button
                  onClick={() => {
                    setIsCsv(true);
                    printFilter();
                  }}
                  leftIcon={<IconDownload />}
                >
                  CSV
                </Button>
                {/* <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    print(false);
                  }}
                >
                  Print
                </Button> */}
                {/* <Button
                  leftIcon={<IconPrinter />}
                  onClick={() => {
                    printFilter();
                  }}
                >
                  Print
                </Button> */}
                <Select
                  label="Counter"
                  defaultValue={isCounterAdmin() ? counter : null}
                  placeholder="Select Counter"
                  data={countersList || []}
                  onChange={setCounter}
                  clearable
                  disabled={isCounterAdmin()}
                />
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}
      {/* STARTS :: Main content */}

      <Container fluid mt={10} mb={30}>
        {/* Add Product Form */}

        {/* Add Product Form */}

        <Box px={isMobileScreen ? 5 : 25} sx={{ borderRadius: 2 }}>
          <Box className="datatable-wrapper">
            <MantineReactTable
              manualFiltering={true} //turn off client-side filtering
              onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
              state={columnFilters} //pass in your own managed columnFilters state
              mantineTableProps={{ className: "datatable", striped: true }}
              renderTopToolbarCustomActions={({ row }) => (
                <Box px={isMobileScreen ? 5 : 24} py={10}>
                  <Text
                    fw={600}
                    tt="uppercase"
                    size={"sm"}
                    sx={theme => ({
                      letterSpacing: 1,
                      color: theme.colors.brand[7],
                    })}
                  >
                    List
                  </Text>
                </Box>
              )}
              initialState={{ density: "xs" }}
              // state={{
              //   isLoading: stock_data.isLoading || stock_data.isRefetching,
              // }}
              columns={columns}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableColumnActions={false}
              // data={products_data.data?.data || []}
              data={stock_data.data?.data || []}
              enableStickyHeader
              enableGlobalFilter={false} //turn off a feature
            />
          </Box>
        </Box>
      </Container>

      {/* ENDS :: Main content */}
      <div style={{ display: "none" }}>
        <PrintModal
          cols={[2, 3, 4, 5]}
          title="Stock List"
          head={head}
          body={body}
          foots={foot}
          ref={componentRef}
        />
      </div>

      {isMobileScreen ? (
        <>
          <FloatingMenu
            m={5}
            left
            size={50}
            onClick={() => {
              setIsCsv(true);
              printFilter();
            }}
          >
            <IconDownload color="white" />
          </FloatingMenu>
          <FloatingMenu
            m={5}
            right
            size={50}
            onClick={() => {
              printFilter();
            }}
          >
            <IconPrinter color="white" />
          </FloatingMenu>
        </>
      ) : (
        <></>
      )}

      <Modal
        opened={productSelectionModal}
        onClose={() => {
          setProductSelectionModal(false);
        }}
        title={
          <Box px={10}>
            <Text fw={"600"}>{modalBatches?.title}</Text>
          </Box>
        }
        size={"850px"}
        centered
      >
        <Box pl={10}>
          <Divider />
        </Box>
        <Box px={10} mb={10}>
          <Box mt={20}>
            <Table
              withColumnBorders
              highlightOnHover
              withBorder
              verticalSpacing={"xs"}
              horizontalSpacing={"xs"}
              className="normal-table"
            >
              <thead>
                <tr>
                  <th> Batch No. </th>
                  <th> Exp. Date </th>
                  <th> No Of Days To Expire </th>
                  <th> Available Stock</th>
                </tr>
              </thead>
              <tbody>
                {modalBatches?.batch && modalBatches?.batch.map(e => {
                  return (
                    <tr
                      key={e.batch_no}
                      tabIndex={0}
                    >
                      <td>{e.batch_no}</td>
                      <td width={120}> {moment(e.exp_date).format("DD-MM-YYYY")} </td>
                      <td> {getExpiryDayLeft(e.exp_date)} </td>
                      <td> {e.stock} </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
