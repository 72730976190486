import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Group,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { enable_cashier, enable_return, return_days } from "config";
import moment from "moment/moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Barcode from "react-barcode";
import { renderIntoDocument } from "react-dom/test-utils";
import QRCode from "react-qr-code";

import { PrintModal } from "./PrintModal";
import { PrintModalA4 } from "./PrintModalA4";
import qr_code from "../static/images/qr_code.png";

export const InvoicePrintA4 = React.forwardRef((props, ref) => {
  const dataPerPage = 14;
  const dataLength = props.data?.invoice_item?.length;
  const pageCount = Math.floor(dataLength / dataPerPage);
  const remainderCount = dataPerPage - (dataLength - dataPerPage * pageCount);
  // console.log(dataLength, pageCount, remainderCount);
  const counter = props.counter;

  //ee
  const from_counter = counter.find(
    (e, i) => e?.contact_person.toLowerCase() === props.data?.counter_person.toLowerCase()
  );
  const to_counter = counter.find(
    (e, i) => e?.contact_person.toLowerCase() === props.data?.name.toLowerCase()
  );
  //dd

  const invoice_date = moment(props.data?.create_at);
  const qty = props.data?.invoice_item?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.qty;
  }, 0);
  const distinctValues = [...new Set(props.data?.invoice_item?.map(item => item.tax))];
  const barcode = (
    <Flex align={"center"} justify={"center"} mb={2}>
      {/* <QRCode size={"50%"} value={props.data?.invoice_id ? props.data?.invoice_id : ""} /> */}
      <QRCode size={"50%"} value={"https://www.quzone.in/"} />
      {/* <img
        src={qr_code}
        width={"50%"}
        style={{
          objectFit: "cover",
        }}
        alt="qrcode"
      /> */}
    </Flex>
  );

  const taxHead = ["CGST+SGST", "Taxable", "Tax"];

  const taxDetails = distinctValues.map(value => [
    value,
    props.data?.invoice_item
      ?.filter(item => item.tax === value)
      .reduce((acc, cur) => acc + (cur.amount - cur.tax_amount), 0)
      .toFixed(2),
    props.data?.invoice_item
      ?.filter(item => item.tax === value)
      .reduce((acc, cur) => acc + cur.tax_amount, 0)
      .toFixed(2),
  ]);

  const Head = () => {
    return (
      <header>
        <Flex align={"center"} justify={"center"} direction={"column"}>
          <Text color="black" fz={26} fw={800} mb={10}>
            TAX INVOICE
          </Text>
        </Flex>
        <Grid>
          <Grid.Col span={4}>
            <Text color="black" fz={"lg"} fw={500}>
              INVOICE FROM
            </Text>
            <Text color="black" fz={"lg"} fw={500}>
              QUZONE
            </Text>
            <Text color="black" fz={"lg"} fw={500}>
              {props.data?.counter_person}
            </Text>
            <Text color="black">FASSAI: {props.data?.counter_fssai}</Text>
            <Text color="black">GST: {props.data?.counter_gst}</Text>
            <Text color="black">Phone No: +91 {props.data?.counter_number}</Text>
            <Text color="black">Address: {from_counter?.address}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="black" fz={"lg"} fw={500}>
              INVOICE TO
            </Text>
            <Text color="black" fz={"lg"} fw={600}>
              {props.data?.name}
            </Text>
            {/* <Text color="black">FASSAI license no: {to_counter?.fssai}</Text> */}
            <Text color="black">GST: {to_counter?.gst}</Text>
            <Text color="black">Phone No: +91 {props.data?.number}</Text>
            <Text color="black">Address: {to_counter?.address}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            {barcode}
            <Text color="black">Bill no: {props.data?.invoice_id}</Text>
            <Text color="black">Data & Time: {invoice_date.format("DD-MM-YYYY HH:MM")}</Text>
          </Grid.Col>
        </Grid>
        <Divider />
      </header>
    );
  };

  const head = [
    "HSN/ SAC code",
    "Item",
    "Mrp",
    "Batch",
    "Qty",
    "Rate",
    "Tax",
    "Tax ₹",
    "Disc",
    "Amount",
  ];
  let body = [];

  let count_amount = 0;

  props.data?.invoice_item?.map((e, i) => {
    let row = [];
    row.push(e.hsn_code);
    row.push(e.product);
    row.push(e.mrp?.toFixed(2));
    row.push(e.batch_no);
    row.push(e.qty);
    row.push(e.rate?.toFixed(2));
    row.push(e.tax?.toFixed(2));
    row.push(e.tax_amount?.toFixed(2));
    row.push(e.discount?.toFixed(2) + "%");
    row.push(e.amount?.toFixed(2));
    count_amount += e.amount;
    body.push(row);
    return null;
  });
  const foot_amount = [
    "",
    "Total",
    `${props.data?.invoice_item?.length} Items`,
    "",
    `${qty} Qty`,
    "",
    "",
    "",
    "Net Amount",
    count_amount?.toFixed(2),
  ];

  for (let index = 0; index < (remainderCount === dataPerPage ? 0 : remainderCount); index++) {
    body.push([""]);
  }

  const head_total = ["", ""];
  const delivery_tax = props.data?.delivery_taxable * (props.data?.delivery_tax / 100);
  const total_tax = props.data?.tax_total + delivery_tax;
  let body_total = [
    ["Sub Total", props.data?.sub_total?.toFixed(2)],
    ["Delviery Charges", props.data?.delivery_taxable?.toFixed(2)],
    ["Packing Charges", props.data?.packing_charge?.toFixed(2)],
    ["Tax Total", total_tax?.toFixed(2)],
    ["Discount Amount", props.data?.discount_total?.toFixed(2)],
    ["Net Amount", props.data?.total?.toFixed(2)],
  ];

  const sameTax = taxDetails.find(v => v[0] === props.data?.delivery_tax);
  if (sameTax !== undefined) {
    taxDetails[taxDetails.findIndex(v => v[0] === props.data?.delivery_tax)] = [
      props.data?.delivery_tax,
      (parseFloat(props.data?.delivery_taxable) + parseFloat(sameTax[1]))?.toFixed(2),
      (parseFloat(delivery_tax) + parseFloat(sameTax[2]))?.toFixed(2),
    ];
  } else if (
    props.data?.delivery_tax !== 0 &&
    props.data?.delivery_taxable !== 0 &&
    delivery_tax !== 0
  ) {
    taxDetails.push([props.data?.delivery_tax, props.data?.delivery_taxable, delivery_tax]);
  }

  const Foot = () => {
    return (
      <footer>
        <Divider />
        <Box>
          <Grid>
            <Grid.Col span={6}>
              <PrintModal
                alignCols={[1, 0, 0]}
                title="Tax Details"
                head={taxHead}
                body={taxDetails}
                removeMargin
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PrintModal alignCols={[1, 0]} head={head_total} body={body_total} removeMargin />
            </Grid.Col>
          </Grid>
          <Divider />
          {enable_cashier ? <Text color="black">Cashier: {props.data?.invoiced_user}</Text> : <></>}
          {enable_return ? (
            <Flex mt={5} align={"center"} justify={"center"} direction={"column"}>
              {return_days !== 0 ? (
                <Text color="black">
                  You can return this item within this date{" "}
                  {invoice_date.add(return_days, "days").format("DD-MM-YYYY HH:MM")}{" "}
                </Text>
              ) : (
                <Text color="black">You can't exchange this item</Text>
              )}
            </Flex>
          ) : (
            <></>
          )}
        </Box>
      </footer>
    );
  };

  let count = 0;
  let pageData = [];
  let allData = [];
  body?.map((e, i) => {
    count = count + 1;
    if (count < dataPerPage) {
      pageData.push(e);
    } else {
      pageData.push(e);
      pageData.push(foot_amount);
      allData.push(pageData);
      pageData = [];
      count = 0;
    }
  });

  return (
    <Box p={10} ref={ref}>
      {allData?.map((e, i) => (
        <Box h={1080} style={{ overflow: "hidden" }}>
          <table style={{ width: "100%", height: "100%" }}>
            <Head />
            <PrintModalA4
              alignCols={[1, 1, 0, 1, 0, 0, 0, 0, 0, 0]}
              divider
              removeMargin
              head={head}
              body={e}
            />
            <Foot />
          </table>
        </Box>
      ))}
    </Box>
  );
});
